import React, { FC } from "react";

interface BreadCrumbProps {
  url?: string;
  mainPage?: string;
  secondPage?: string;
}

const BreadCrumb: FC<BreadCrumbProps> = ({
  url = "/",
  mainPage = "",
  secondPage = "",
}) => {
  return (
    <>
      <section className="breadcrumb_section">
        <div className="container">
          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href={url}>{mainPage}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {secondPage}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};
export default BreadCrumb;
