import React, { useEffect, useState } from "react";

const BottomSection = (propData) => {
  const [dataBot, setBotData] = useState<any>();

  useEffect(() => {
    console.log("prop bot", propData);
    if (propData) {
      setBotData({ ...propData });
    }
  }, [propData]);

  useEffect(() => {
    // alert("trig");
    console.log("data bo", dataBot);
  }, [propData, dataBot]);

  return (
    <>
      {dataBot && dataBot.propData && (
        <section className="courses_bottom_section">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-1 col-md-5 col-lg-4 tabpane">
                <div role="tabpanel">
                  <ul
                    className="nav nav-tabs tab-wrapper"
                    id="myTab"
                    role="tablist"
                  >
                    {dataBot &&
                      dataBot.propData &&
                      dataBot.propData.mainTabs.map((item) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={item.id}
                        >
                          <a
                            className={
                              item.id == 1 ? "nav-linkk active" : "nav-linkk"
                            }
                            id={`${item.tabId}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#tab-${item.id}`}
                            type="button"
                            role="tab"
                            aria-controls={item.tabId}
                            aria-selected="true"
                          >
                            <p>{item.tabName}</p>
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-7 col-md-7 col-lg-8 content-wrapper">
                <div className="tab-content">
                  {dataBot &&
                    dataBot.propData &&
                    dataBot.propData.tabData.map((item) => (
                      <div
                        className={
                          item.id == 1 ? "tab-pane active" : "tab-pane"
                        }
                        id={`tab-${item.id}`}
                        role="tabpanel"
                        aria-labelledby={`${item.tabId}-tab`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.tabDes,
                          }}
                        />
                      </div>
                    ))}
                  {/* <div
                    className="tab-pane active"
                    id="tab-1"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataBot.propData.tabData.tab1.tabDes,
                      }}
                    />
                  </div>
                  <div
                    className="tab-pane"
                    id="tab-2"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataBot.propData.tabData.tab2.tabDes,
                      }}
                    />
                  </div>
                  <div
                    className="tab-pane"
                    id="tab-3"
                    role="tabpanel"
                    aria-labelledby="messages-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataBot.propData.tabData.tab3.tabDes,
                      }}
                    />
                  </div>
                  <div
                    className="tab-pane"
                    id="tab-4"
                    role="tabpanel"
                    aria-labelledby="messages-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataBot.propData.tabData.tab4.tabDes,
                      }}
                    />
                  </div>
                  <div
                    className="tab-pane"
                    id="tab-5"
                    role="tabpanel"
                    aria-labelledby="messages-tab"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataBot.propData.tabData.tab5.tabDes,
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BottomSection;
