import React, { useState } from "react";
import About from "../../../assets/images/about_us.jpg";
import VideoModal from "../../../components/videoModal";

const AboutCompany = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    // window.scrollTo(0, 50);
    // document.getElementById("nav-header").style.display = "none !important";

    setModalIsOpen(true);
  };

  const closeModal = () => {
    // window.scrollTo(0, 0);
    // document.getElementById("nav-header").style.display = "block !important";

    setModalIsOpen(false);
  };
  return (
    <section className="content_image_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <h2>Pioneer of the online business education</h2>
            <p>eClub Business College (Pvt) Ltd is a registered educational institute in TVEC (P03/0162) that has revolutionized the educational system in Sri Lanka. While located physically in Panadura, eClub was able to reach a global context through digitalization. In almost every operation, we conduct classes online and offline. We become the pioneers in the educational stream, earning dollars through online platforms.
             </p>
            <p>
              In the online business sector, we are on a mission to boost the
              Sri Lankan youth. eClub Business College has remarkably upskilled
              100,000+ students in varied course areas energized by a panel of
              graduate-trained instructors since 1st June 2017.
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 align-self-center">
            <div className="row  text-center">
              <div
                onClick={() => openModal()}
                className="img-fluid pull-right"
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <img
                  src={About}
                  className="img-fluid pull-right"
                  alt="Image2"
                  style={{ borderRadius: "20px" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="100"
                    width="100"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    onMouseOver={(e) => (e.currentTarget.style.fill = "red")}
                    onMouseOut={(e) => (e.currentTarget.style.fill = "#fff")}
                    onClick={() => openModal()}
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              </div>
              <VideoModal
                videoUrl={"https://youtu.be/i7_nnWT67YA"}
                isOpen={modalIsOpen}
                onClose={closeModal}
              />
              {/* <img
                src={require("../../../assets/images/about-us-page/about-us-1.png")}
                className="img-responsive"
                alt="about"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompany;
