import React from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { StudentLifeBanner, StaffImg } from "../../assets/mockData/common";
import test from "../../assets/images/life_at_club/test1.png";
import CustomSlider from "../../components/CustomSlider";
import { lifeAtEclubSlider } from "../../config/sliders";
import {
  StudentsLife,
  AcademicExcellence,
  EventsActivitie,
  OurStuff,
} from "../../assets/mockData/lifeAtEclub";
const StudentLife = () => {
  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <section
          className="common_banner "
          style={{
            backgroundImage: `url(${StudentLifeBanner})`,
          }}
        >
          <div className="container">
            <ol className="breadcrumb breadcrumb-type-1">
              <li>
                <a href="/">Home</a>
              </li>
              <li className="active" aria-current="page">
                &ensp;/&ensp;Life at eClub
              </li>
            </ol>
            {/* <BreadCrumb mainPage="Home" secondPage="Life at eClub" /> */}

            <div className="row row-clr  heading-row">
              <h2>Grow With Us</h2>
            </div>
          </div>
        </section>
        <section className="content_section">
          <div className="container">
            <div className="row row-clr">
              <p>
                Welcome to a world of opportunity, innovation, and digital
                entrepreneurship. At eClub, we are your gateway to mastering the
                art of online businesses and e-commerce. Our mission is to equip
                aspiring entrepreneurs and business professionals with the
                knowledge and skills they need to thrive in the digital economy.
              </p>
            </div>
          </div>
        </section>

        <div className="container">
          <section
            className="image_content_section"
            style={{ backgroundColor: "blue" }}
          >
            <div>
              <div className="row ">
                <div className="col-xs-12 col-md-6  lifeAtEclubSlider">
                  <CustomSlider
                    selector=".lifeAtEclubSlider"
                    settings={lifeAtEclubSlider}
                  >
                    {StudentsLife.map((stu) => (
                      <div style={{ width: "780px" }}>
                        <img
                          src={`${stu}`}
                          // width={"780px"}
                          alt="Slide 1"
                          className="imgStyle"
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </CustomSlider>
                </div>
                <div className="col-xs-12 col-md-6 content_wrapper custpaddingRight align-self-center ">
                  <h2>Student Life</h2>
                  <p>
                    Life at eClub is all about exploring the limitless
                    possibilities of online entrepreneurship. Dive into our
                    courses and engage with a community of like-minded
                    individuals. You'll have the chance to network, collaborate,
                    and launch your own online ventures.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
            className="image_content_section"
            style={{ backgroundColor: "orange" }}
          >
            <div>
              <div className="row right-te">
                <div className="col-xs-12 col-md-6 content_wrapper align-self-center custpaddingLeft">
                  <h2>Academic Excellence</h2>
                  <p>
                    Our lecturer panel is industry experts with real-world
                    experience in the digital business sphere. We offer a range
                    of courses and programs that cover e-commerce, personal
                    development, IT education, and language education. We're
                    committed to providing practical knowledge that you can
                    apply immediately.
                  </p>
                </div>
                <div className="col-xs-12 col-md-6  lifeAtEclubSlider">
                  <CustomSlider
                    selector=".lifeAtEclubSlider"
                    settings={lifeAtEclubSlider}
                  >
                    {AcademicExcellence.map((acd) => (
                      <div>
                        <img
                          src={`${acd}`}
                          className="imgStyle"
                          alt="Slide 1"
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </CustomSlider>
                </div>
              </div>
            </div>
          </section>
          <section
            className="image_content_section"
            style={{ backgroundColor: "green" }}
          >
            <div>
              <div className="row">
                <div className="col-xs-12 col-md-6 lifeAtEclubSlider">
                  <CustomSlider
                    selector=".lifeAtEclubSlider"
                    settings={lifeAtEclubSlider}
                  >
                    {OurStuff.map((stu) => (
                      <div>
                        <img
                          src={`${stu}`}
                          className="imgStyle"
                          alt="Slide 1"
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </CustomSlider>
                </div>
                <div className="col-xs-12 col-md-6 content_wrapper custpaddingRight align-self-center">
                  <h2>Our Staff</h2>
                  <p>
                    Our fully qualified non-academic staff is committed to
                    providing the services required by the students as an
                    institution by providing the right guidance to all the
                    students joining the institution.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
            className="image_content_section"
            style={{ backgroundColor: "red" }}
          >
            <div>
              <div className="row right-te">
                <div className="col-xs-12 col-md-6 content_wrapper custpaddingLeft align-self-center">
                  <h2>Events & Activities</h2>
                  <p>
                    From get-togethers and parties to festive celebrations, we
                    provide opportunities for students to unwind, socialize, and
                    create unforgettable memories. These events offer a break
                    from your studies and a chance to connect with fellow
                    students, instructors, and industry professionals in a
                    relaxed, informal setting.
                  </p>
                </div>
                <div className="col-xs-12 col-md-6  lifeAtEclubSlider">
                  <CustomSlider
                    selector=".lifeAtEclubSlider"
                    settings={lifeAtEclubSlider}
                  >
                    {EventsActivitie.map((evt) => (
                      <div style={{ width: "820px" }}>
                        <img
                          src={`${evt}`}
                          className="imgStyle"
                          alt="Slide 1"
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </CustomSlider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default StudentLife;
