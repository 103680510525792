import React from "react";
import BreadCrumb from "../../components/BreadCrumb";
import Media2 from "../../assets/images/media-page/media-2.png";
import Media1 from "../../assets/images/media-page/media-1.svg";
import GridAnimated from "../../components/masonaryGrid/MasonaryGrid";

const Media = () => {
  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <BreadCrumb mainPage="Home" secondPage="Media / Gallery" />
        <section className="media_image_section">
          <div className="container">
            <div className="row row-clr heading-row">
              <h2>
                <img src={Media1} className="img-fluid" alt="Image1" />
              </h2>
            </div>
            <div className="row filter-wrapper">
              <GridAnimated />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Media;
