import React from "react";
import CustomSlider from "../../components/CustomSlider";
import { scholarsSlider } from "../../config/sliders";
import BreadCrumb from "../../components/BreadCrumb";
// import Scholar1 from "../../assets/images/scholarships/scholarships-02.png";
import { Schol } from "../../assets/mockData/common";

const Scholarships = () => {
  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <BreadCrumb mainPage="Home" secondPage="Scholarships" />

        <section className="scholarships_detail_section">
          <div className="container">
            <div className="row row-clr heading-row">
              <h2>Scholarships</h2>
              <h5>
                Scholarship programs are like keys that open doors to education.
                eClub Business College organizes scholarship programs together
                with eClub Youth, to help deserving individuals by providing
                financial aid, often based on various criteria such as academic
                achievement, merit or community involvement. These scholarship
                awarding programs are organized annually and in the relevant
                program for the year 2023, scholarships were awarded to nearly
                50 students on August 21.
              </h5>
            </div>
            {/* <div className="row row-clr tab-section">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-1"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Entrance Scholarships
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-2"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Scholarships for Continues Students
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  className="tab-pane active"
                  id="tab-1"
                  role="tabpanel"
                  aria-labelledby="#tab-1"
                >
                  <div className="row row-clr inner-row">
                    <h2>Scholarship No 1</h2>
                    <p>
                      This is offered to the student who ranks highest in the
                      G.C.E (A/L) Mathematics or Commerce stream examination
                      results in the country. The student will be eligible for a
                      Full Tution fee waiver and a monthly stipend for the
                      Academic Semester as a fulltime student
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section className="scholarships_testimonial_section">
          <div className="container">
            <div className="row h_align">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 left-wrapper">
                <img
                  src={require("../../assets/images/scholarships/scholarships-01.png")}
                  className="img-fluid"
                  alt="Image1"
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 right-wrapper">
                <h2>Hear From Our Scholars</h2>
                <div className="row row-clr scholars-slider">
                  <CustomSlider
                    selector=".scholars-slider"
                    settings={scholarsSlider}
                  >
                    <div className="slider-inner">
                      <div className="user-wrapper">
                        <div
                          className="image-wrapper"
                          style={{
                            backgroundImage: `url(${Schol})`,
                          }}
                        ></div>
                        <div className="user-detail">
                          <h3>Thihara Chamathka</h3>
                          <h6>Student of eClub</h6>
                        </div>
                      </div>
                      <div className="content-wrapper">
                        <p>
                          නොමිලේ පාඨමාලවන් හදාරන්න ශිෂ්‍යත්වය ලබාදෙන ආයතන අද
                          ලංකාවේ ගොඩක් අඩුයි. ඒ වගේ අවස්ථාවක මේ වගේ CIT පාඨමාලාව
                          හදාරන්න ශිෂ්‍යත්වයක් ලැබීම ගොඩක් වටිනවා මට. ඒ අවස්ථාව
                          මට ලබා දුන්න eClub Business College ආයතනයට මම ගොඩක්
                          ස්තූතිවන්ත වෙනවා සහ තවත් මේ වගේ ප්‍රජා සත්කාරයන්
                          කිරීමට හැකියාව ලැබේවා කියලා ප්‍රාර්ථනා කරනවා.
                        </p>
                      </div>
                    </div>
                    {/* <div className="slider-inner">
                      <div className="user-wrapper">
                        <div
                          className="image-wrapper"
                          style={{
                            backgroundImage: `url(${Schol})`,
                          }}
                        ></div>
                        <div className="user-detail">
                          <h3>Thihara Chamathka</h3>
                          <h6>Student of eClub</h6>
                        </div>
                      </div>
                      <div className="content-wrapper">
                        <p>
                          නොමිලේ පාඨමාලවන් හදාරන්න ශිෂ්‍යත්වය ලබාදෙන ආයතන අද
                          ලංකාවේ ගොඩක් අඩුයි. ඒ වගේ අවස්ථාවක මේ වගේ CIT පාඨමාලාව
                          හදාරන්න ශිෂ්‍යත්වයක් ලැබීම ගොඩක් වටිනවා මට. ඒ අවස්ථාව
                          මට ලබා දුන්න eClub Business College ආයතනයට මම ගොඩක්
                          ස්තූතිවන්ත වෙනවා සහ තවත් මේ වගේ ප්‍රජා සත්කාරයන්
                          කිරීමට හැකියාව ලැබේවා කියලා ප්‍රාර්ථනා කරනවා.
                        </p>
                      </div>
                    </div>
                    <div className="slider-inner">
                      <div className="user-wrapper">
                        <div
                          className="image-wrapper"
                          style={{
                            backgroundImage: `url(${Schol})`,
                          }}
                        ></div>
                        <div className="user-detail">
                          <h3>Thihara Chamathka</h3>
                          <h6>Student of eClub</h6>
                        </div>
                      </div>
                      <div className="content-wrapper">
                        <p>
                          නොමිලේ පාඨමාලවන් හදාරන්න ශිෂ්‍යත්වය ලබාදෙන ආයතන අද
                          ලංකාවේ ගොඩක් අඩුයි. ඒ වගේ අවස්ථාවක මේ වගේ CIT පාඨමාලාව
                          හදාරන්න ශිෂ්‍යත්වයක් ලැබීම ගොඩක් වටිනවා මට. ඒ අවස්ථාව
                          මට ලබා දුන්න eClub Business College ආයතනයට මම ගොඩක්
                          ස්තූතිවන්ත වෙනවා සහ තවත් මේ වගේ ප්‍රජා සත්කාරයන්
                          කිරීමට හැකියාව ලැබේවා කියලා ප්‍රාර්ථනා කරනවා.
                        </p>
                      </div>
                    </div> */}
                  </CustomSlider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Scholarships;
