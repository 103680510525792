import React, { useContext, useState } from "react";
// import About from "../../../assets/images/home/about-1.png";
import About from "../../../assets/images/about_us.jpg";
import VideoModal from "../../../components/videoModal";

const AboutUs = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const videoUrl = VideoModal;
  const thumbnailUrl = "https://example.com/your-thumbnail.jpg";
  return (
    <section className="about_us_section">
      <div className="container">
        <div className="row  heading-row" data-aos="fade-down">
          <h2>About Us</h2>
        </div>
        {/* <!-- heading-row --> */}
        <div className="row">
          <div
            className="col-xs-12 col-sm-6 col-md-6 col-lg-6 content-wrapper"
            data-aos="fade-right"
          >
            <p>
              eClub Business College is a sri Lankan educational institution
              established under the Companies Act No. 07 of 2007 with institute
              number PV 122779. The college owns two premises at No.651,
              Nalluruwa, Galle Road, Panadura & No. 242, Horana Road, Wekada,
              Panadura.
            </p>
            <a href="/about" className="more-button-1">
              More
            </a>
          </div>
          <div
            className="col-xs-12 col-sm-6 col-md-6 col-lg-6"
            data-aos="fade-left"
          >
            <div className="image-with-link">
              <div
                onClick={() => openModal()}
                className="row row-clr image-clip-wrapper"
                style={{
                  backgroundImage: `url(${About})`,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="100"
                    width="100"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    onMouseOver={(e) => (e.currentTarget.style.fill = "red")}
                    onMouseOut={(e) => (e.currentTarget.style.fill = "#fff")}
                    onClick={() => openModal()}
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              </div>
              <a href="/about" className="link-button-1">
                Link
              </a>
            </div>
            <VideoModal
              videoUrl={"https://youtu.be/i7_nnWT67YA"}
              isOpen={modalIsOpen}
              onClose={closeModal}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
