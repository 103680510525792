import React from "react";
import About5 from "../../../assets/images/about-us-page/about-5.png"
import About6 from "../../../assets/images/about-us-page/about-6.png"

const Directors = () => {
  return (
    <section className="message_directors_section">
            <div className="container">
               <div className="row row-clr heading-row">
                  <h2>Message From Directors</h2>
               </div>
               <div className="row contnet-row">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                     <img src={About5} className="img-fluid" alt="Image2"/>
                     <div className="contnet-wrapper">
                        <h3>Roy Malcolm Perera</h3>
                        <h6>Bachelor of Commerce (USJ), MAAT, LICA</h6>
                        <h4>Managing Director </h4>
                        <p>
                           At E Club, our primary focus is your growth and success. We understand that the online business landscape is dynamic and ever-changing, and we're here to provide you with the knowledge and skills you need to thrive in this environment. We continuously strive to enhance our course offerings, collaborate with industry experts, and create a supportive learning environment.
                        </p>
                     </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                     <img src={About6} className="img-fluid" alt="Image3"/>
                     <div className="contnet-wrapper">
                        <h3>Dulaj Edirisinghe</h3>
                        <h6>Bachelor of Commerce Special in Entrepreneurship (Kel'ya)</h6>
                        <h4>Director Administration</h4>
                        <p>
                           In our pursuit of excellence, we are committed to creating an environment that is conducive to learning and growth. We work diligently to streamline processes, enhance efficiency, and maintain the facilities and resources that you rely on for your education
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
  );
};

export default Directors;
