import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import eclub1 from "../assets/video/eClub1.mp4";
import ReactPlayer from "react-player/lazy";
import { BsX } from "react-icons/bs";
import { motion } from "framer-motion";
import { NavBarContext } from "../assets/contexts/navBarContext";

interface VideoModalProps {
  videoUrl: string;
  isOpen: boolean;
  onClose: () => void;
  id?: number;
}

const VideoModal = ({ videoUrl, isOpen, onClose, id }: VideoModalProps) => {
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [display, setDisply] = useContext(NavBarContext);

  useEffect(() => {
    console.log("isOpen", isOpen);
    const isMobile = window.innerWidth <= 767;
    const windowidth = window.innerWidth;
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    if (isOpen) {
      setDisply({ ...display, navHide: "none", overlayHide: true });
      document.body.style.overflow = "hidden";
      // document.body.style.paddingTop = "0";
      document.body.style.paddingTop =
        isMobile || (isLandscape && windowidth < 1000) ? "" : "0";
    } else {
      setDisply({ ...display, navHide: "block", overlayHide: false });
      document.body.style.overflow = "visible";
      // document.body.style.paddingTop = "140px";
      document.body.style.paddingTop =
        isMobile || (isLandscape && windowidth < 1000) ? "" : "140px";
    }
  }, [isOpen]);

  const openVideo = () => {
    setVideoVisible(true);
  };

  const closeVideo = () => {
    // setDisply("block");
    setDisply({ ...display, navHide: "block", overlayHide: false });

    setVideoVisible(false);
    onClose();
  };

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onRequestClose={closeVideo}
      contentLabel="Video Modal"
      className="common-video-modal"
    >
      <div className=" video-modal-container">
        <motion.button
          className="close-button"
          onClick={closeVideo}
          whileHover={{ scale: 1.1, rotate: 360 }}
          whileTap={{ scale: 0.9 }}
        >
          <BsX className="close-icon" />
        </motion.button>
        <ReactPlayer
          url={videoUrl}
          width="100%"
          height="calc(100vh - 100px)"
          controls={true}
          style={{ padding: "19px" }}
        />
      </div>
    </Modal>
  );
};

export default VideoModal;
