import React, { useEffect, useState } from "react";
import HeaderSection from "./components/headerSection";
import BottomSection from "./components/bottomSection";
import CrDetails from "../../assets/images/courses-detail/courses-detail-1.svg";
import { useParams } from "react-router-dom";
import { courseDetails } from "../../assets/mockData/courseDetails";

const CourseDetails = () => {
  const { courseId = "JLPT N5" } = useParams();
  const details = courseDetails;
  const [data, setData] = useState<any>({});

  useEffect(() => {
    console.log("filt122", courseId);

    if (courseId) {
      const filteredCourse = details.filter(
        (course) => course.courseId === courseId
      );
      console.log("filt1", filteredCourse[0]);
      console.log("filt2", details);
      if (filteredCourse[0]) {
        setData(filteredCourse[0]);
      }
    }
  }, [courseId, details]);

  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <section
          className="courses_banner_section"
          style={{
            backgroundImage: `url(${data.banner})`,
          }}
        >
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Courses</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {data.courseName}
                </li>
              </ol>
            </nav>
          </div>
        </section>
        <HeaderSection propData={data} />
        <BottomSection propData={data.moreInfo} />
      </main>
    </>
  );
};
export default CourseDetails;
