import React, { useEffect, useState } from "react";
import ParticleComp from "../../../components/particleComp";
import MyCalendar from "../../../components/Calendar";
import CustomSlider from "../../../components/CustomSlider";
import { eventSlider } from "../../../config/sliders";

const Events = () => {
  const [content, setContent] = useState<any[]>([]);

  useEffect(() => {
    console.log("content", content);
  }, [content]);

  return (
    <section className="event_calender_section">
      <ParticleComp>
        <div className="container">
          <div className="row  heading-row" data-aos="fade-down">
            <h2>Event Calender</h2>
          </div>
          {/* <img
            src={require("../../../assets/images/home/event-calender-1.png")}
            className="img-fluid"
            alt="calender"
            data-aos="fade-down"
          /> */}
          <div className="row event_area ">
            <div className="event-calendar-area col-8">
              <div className="calendar-event-container">
                <MyCalendar content={(e) => setContent(e)} />
              </div>
            </div>
            <div className="event-details-area">
              <CustomSlider
                selector=".lifeAtEclubSlider"
                settings={eventSlider}
              >
                {content.map((stu, index) => (
                  <>
                    <img
                      src={
                        stu.image ??
                        "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/event/kane-reinholdtsen-LETdkk7wHQk-unsplash.jpg"
                      }
                      className="event-image"
                    />
                    <div
                      className="description-area"
                      dangerouslySetInnerHTML={{
                        __html: stu.content ?? "",
                      }}
                    ></div>
                  </>
                ))}
              </CustomSlider>
            </div>
            {/* <div className="event-details-area ">
              <img
                src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/event/kane-reinholdtsen-LETdkk7wHQk-unsplash.jpg"
                className="event-image"
              />
              {content && (
                <div
                  className="description-area"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                ></div>
              )}
            </div> */}
          </div>
        </div>
      </ParticleComp>
    </section>
  );
};

export default Events;
