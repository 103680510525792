import React from "react";
import NewsImg from "../../../assets/images/news/news-02.png";
// import Gala from "../../../assets/images/news/gala_night.jpg";
import Goal from "../../../assets/images/news/goal_settingg.jpg";
import GetTogether from "../../../assets/images/news/daily-news.jpg";
const event1 = "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/event-04-08.jpeg";
const Gala =
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/galanightHome.jpg";

const News = () => {
  return (
    <section className="home_news_section">
      <div className="container">
        <div className="row  heading-row" data-aos="fade-down">
          <h2>News & Events</h2>
          <a data-aos="fade-left" href="/news" className="more-button">
            More
          </a>
        </div>
        <div className="row contnet-row">          {/* <!-- col-xs-12 col-sm-4 col-md-4 col-lg-4 --> */}
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4 inner-col"
            data-aos="fade-left"
          >
            <div className="image-with-link">
              <div
                className="row row-clr image-clip-wrapper"
                style={{
                  backgroundImage: `url(${event1})`,
                }}
              >
                {/* <h3 className="heading">eClub Gala Night 2023</h3> */}
                <h5 className="date">01/10/2023</h5>
              </div>
              <div className="read-more-wrapper">
                <h4>
                  <a href="/news">Read More</a>
                </h4>
              </div>
            </div>
            {/* <!-- image-with-link --> */}
            <div className="content-wrapper">
        
     
<p>ඔබේ හෙට වෙනුවෙන් කරන හොඳම ආයෝජනය අද ඉගෙනීමයි.

පරිගණකය හසුරුවා ගැනීම , මෘදුකාංග භාවිතය සහ තොරතුරු තාක්ෂණය සමඟ මුහුවීමේ හැකියාව තුළින් වැඩ ලෝකයට පිවිසීමට බලාපොරොත්තු වන ඔබගේ සිහින යාථාර්ථයක් බවට පත් කරනු ඇත.

තොරතුරු තාක්ෂණවේදය පිළිබඳ සහතික පත්‍ර Online පාඨමාලාවේ මීළඟ කණ්ඩායම මැයි 8 වන දින ආරම්භ වේ.

මූලික දැනුමක් අවශ්‍ය නොවන අතර වසර ගණනාවක් පළපුරුදු සහිත දිවයිනේ දක්ෂ ඇදුරු මඬුල්ලක් විසින් මෙහෙය වන මෙම පාඨමාලාව සඳහා පරිගණක දැනුම අවශ්‍ය ඕනෑම අයෙකුට අයදුම් කළ හැකිය.

විස්තර දැනගැනීමට අදම අප සමඟ සම්බන්ධ වන්න.</p>
            </div>
            {/* <!-- content-wrapper --> */}
          </div>
          {/* <!-- col-xs-12 col-sm-4 col-md-4 col-lg-4 --> */}
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4 inner-col"
            data-aos="fade-left"
          >
            <div className="image-with-link">
              <div
                className="row row-clr image-clip-wrapper"
                style={{
                  backgroundImage: `url(${GetTogether})`,
                }}
              >
                {/* <h3 className="heading">Get together 2023</h3> */}
                <h5 className="date">01/10/2023</h5>
              </div>
              <div className="read-more-wrapper">
                <h4>
                  <a href="/news">Read More</a>
                </h4>
              </div>
            </div>
            {/* <!-- image-with-link --> */}
            <div className="content-wrapper">
              <p>
                Team building, leadership development, and personal growth.
                Participants engage in a variety of outdoor activities,
                problem-solving challenges, and team exercises to build skills
                like communication, leadership, teamwork, and problem-solving.
              </p>
            </div>
            {/* <!-- content-wrapper --> */}
          </div>
          {/* <!-- col-xs-12 col-sm-4 col-md-4 col-lg-4 --> */}

          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4 inner-col"
            data-aos="fade-left"
          >
            <div className="image-with-link">
              <div
                className="row row-clr image-clip-wrapper"
                style={{
                  backgroundImage: `url(${Goal})`,
                }}
              >
                <h3 className="heading">Goal Setting 2024</h3>
                <h5 className="date">21/12/2024</h5>
              </div>
              <div className="read-more-wrapper">
                <h4>
                  <a href="/news">Read More</a>
                </h4>
              </div>
            </div>
            {/* <!-- image-with-link --> */}
            <div className="content-wrapper">
              <p>
                A program called Goal Setting will be held on December 21, 2023
                for all course consultant officers to discuss the marketing
                forecast for the year 2023 and the marketing plan for the year
                2024. Nearly 500 course consultant officers are going to
                participate in this program.
              </p>
            </div>
            {/* <!-- content-wrapper --> */}
          </div>

        </div>
      </div>
    </section>
  );
};

export default News;
