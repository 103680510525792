import React from "react";
import LifeClubImg from "../../../assets/images/home/lifeatclub-1.png";
import Academic from "../../../assets/images/life_at_club/Academic_Excellence.jpg";
import Youth from "../../../assets/images/life_at_club/1youth.jpg";

const LifeClub = () => {
  return (
    <section className="life_at_club_section">
      <div className="container">
        <div className="row  heading-row" data-aos="fade-down">
          <h2>Life at eClub</h2>
          <a data-aos="fade-left" href="/life_at_club" className="more-button">
            More
          </a>
        </div>
        {/* <!-- heading-row --> */}
        <div className="row contnet-row">
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4 inner-col"
            data-aos="fade-left"
          >
            <div className="image-with-link">
              <div
                className="row row-clr image-clip-wrapper"
                style={{
                  backgroundImage: `url(${LifeClubImg})`,
                }}
              ></div>
              <a href="/life_at_club" className="link-button-1">
                Link
              </a>
            </div>
            {/* <!-- image-with-link --> */}
            <div className="content-wrapper">
              <p>
                Our fully qualified non-academic staff is committed to providing
                the services required by the students as an institution by
                providing the right guidance to all the students joining the
                institution.
              </p>
            </div>
            {/* <!-- content-wrapper --> */}
          </div>
          {/* <!-- col-xs-12 col-sm-4 col-md-4 col-lg-4 --> */}
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4 inner-col"
            data-aos="fade-down"
          >
            <div className="content-wrapper">
              <p>
                Our lecturer panel is industry experts with real-world
                experience in the digital business sphere. We offer a range of
                courses and programs that cover e-commerce, personal
                development, IT education, and language education. We're
                committed to providing practical knowledge that you can apply
                immediately.
              </p>
            </div>
            {/* <!-- content-wrapper --> */}
            <div className="image-with-link">
              <div
                className="row row-clr image-clip-wrapper"
                style={{
                  backgroundImage: `url(${Academic})`,
                }}
              ></div>
              <a href="/life_at_club" className="link-button-1">
                Link
              </a>
            </div>
            {/* <!-- image-with-link --> */}
          </div>
          {/* <!-- col-xs-12 col-sm-4 col-md-4 col-lg-4 --> */}
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4 inner-col"
            data-aos="fade-right"
          >
            <div className="image-with-link">
              <div
                className="row row-clr image-clip-wrapper"
                style={{
                  backgroundImage: `url(${Youth})`,
                }}
              ></div>
              <a href="/life_at_club" className="link-button-1">
                Link
              </a>
            </div>
            {/* <!-- image-with-link --> */}
            <div className="content-wrapper">
              <p>
                The relationships you form at business college can extend beyond
                eClub youth is a non-profit organization registered by the
                government as a welfare society. The membership of eClub youth
                can be obtained only for the students who have completed the
                courses of eClub business college and the entire staff including
                the course consultant officers.
              </p>
            </div>
            {/* <!-- content-wrapper --> */}
          </div>
          {/* <!-- col-xs-12 col-sm-4 col-md-4 col-lg-4 --> */}
        </div>
      </div>
    </section>
  );
};

export default LifeClub;
