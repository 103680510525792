export const YouthDetails = [
  {
    id: 1,
    color: "#001DA5",
    Heading: "Certificate & Scholarship awarding ceremony 2022",
    body: "In December 2022, the eClub Youth Association organized a ceremony to award certificates to 150 students who successfully completed courses in Graphic Design, CIT, Japanese, where more than 100 students were also awarded scholarships.",
    images: [
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/certificates_and_scholarship_awarding_cerrmony/eclub_2.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/certificates_and_scholarship_awarding_cerrmony/eclub_3.jpg",
    ],
  },
  {
    id: 2,
    color: "#F36400",
    Heading: "Beach Cleaning 2022",
    body: "eClub Youth society conducted a Panadura beach cleaning program in October 2022 with the participation of over 200 members to save the Panadura beach.",
    images: [
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/01.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/02.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/03.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/04.jpg",
    ],
  },
  {
    id: 3,
    color: "#59C600",
    Heading: "Bood Donation Program 2023",
    body: "eClub Youth society organized a blood donation program in January 2023 with the participation of more than 250 members as another community service.",
    images: [
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_1.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_4.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_5.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_6.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_7.jpg",
    ],
  },
  {
    id: 4,
    color: "#C8090B",
    Heading: "Giving alms to worshipers in Navam Poya day",
    body: "The eClub Youth society organized the noon alms giving for more than 100 worshipers in Navam Poya day on February 05, 2023.",
    images: [
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_1.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_2.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_3.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_4.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_5.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_6.jpg",
    ],
  },
  {
    id: 5,
    color: "#59C600",
    Heading: "Pirith Chant 2023",
    body: "eClub Youth society organized a Pirith chant at the beginning of New Year 2023 with the participation of 300 members.",
    images: [
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/01.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/02.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/03.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/04.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/004.jpg",
    ],
  },
  {
    id: 6,
    color: "#C8090B",
    Heading: "Certificate & Scholarship awarding ceremony 2023",
    body: "In August 2023, the eClub Youth society organized a ceremony to award certificates to 200 students who successfully completed courses in Graphic Design, CIT, Japanese, where more than 150 students were also awarded scholarships.",
    images: [
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/certificates_and_scholarship_awarding_cerrmony/eclub_5.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/certificates_and_scholarship_awarding_cerrmony/eclub_6.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/certificates_and_scholarship_awarding_cerrmony/eclub_5_1.jpg",
    ],
  },
  {
    id: 7,
    color: "#001DA5",
    Heading: "Poson Dansala 2023",
    body: "In June 2023, the eClub Youth society organized a Poson Dansala with the participation of more than 200 members as another community service.",
    images: [
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/01.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/02.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/04.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/000006.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/00006.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/2.jpg",
      "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/4.jpg",
    ],
  },
  {
    id: 8,
    color: "#F36400",
    Heading: "Giving alms to pregnant mothers",
    body: "The eClub Youth society organized a giving alms program to pregnant mothers with economic difficulties in September 2023. More than 50 mothers participated in this program.",
  },
];

export const YouthCommonImgs = [
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/awarding_ceremony/02.jpg",
  // "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/awarding_ceremony/04.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/awarding_ceremony/03.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/awarding_ceremony/01.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/awarding_ceremony/05.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_1.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_2.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_3.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_4.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_5.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_navan_poho_dina/eclub_6.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/01.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/02.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/03.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/2023_prith_sdgayana/04.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/01.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/02.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/03.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/beach_cleaning/04.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_1.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_4.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_5.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_6.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_7.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_1.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_2.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_3.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_5.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/blood_donation/eclub_6.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/01.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/2.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/4.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/02.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poson_dansala/04.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poya_photos/eclub_youth_1.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poya_photos/eclub_youth_2.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poya_photos/eclub_youth_3.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poya_photos/eclub_youth_4.jpg",
  "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/eclub_youth/poya_photos/eclub_youth_8.jpg",
];
