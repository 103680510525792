import React from "react";
import ContactImg from "../../../assets/images/contact/Contact.png"

const Banner = () => {
  return (
    <section className="contact_banner">
      <div className="container">
        <div
          className="row row-clr inner-banner"
          style={{
            backgroundImage: `url(${ContactImg})`
          }}
        >
          <h2>We Are Here For You</h2>
        </div>
      </div>
    </section>
  );
};

export default Banner;
