import React, { useEffect, useState } from "react";
import { sliderEclubYouth } from "../../config/sliders";
import CustomSlider from "../../components/CustomSlider";
import YouthImg from "../../assets/images/eclub_youth/eclub_youth-01.png";
import YouthImg3 from "../../assets/images/eclub_youth/eclub_youth-03.png";
import {
  YouthCommonImgs,
  YouthDetails,
} from "../../assets/mockData/YouthDetails";
import YouthCard from "../../components/YouthCard";
import { YouthBanner } from "../../assets/mockData/common";
import BreadCrumb from "../../components/BreadCrumb";

const Youth = () => {
  const [youthData, setYouthData] = useState<any[]>([]);
  const [youthCommon, setYouthCommon] = useState<any[]>([]);

  useEffect(() => {
    setYouthData(YouthDetails);
    setYouthCommon(YouthCommonImgs);
  }, [YouthDetails, YouthCommonImgs]);

  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <section
          className="common_banner"
          style={{
            backgroundImage: `url(${YouthBanner})`,
          }}
        >
          <div className="container">
            <ol className="breadcrumb breadcrumb-type-1">
              <li>
                <a href="/">Home</a>
              </li>
              <li className="active">&ensp;/&ensp;eClub Youth</li>
            </ol>

            <div className="row row-clr">
              <h2>Empowering Youth</h2>
            </div>
          </div>
        </section>
        <section className="about_club_section">
          <div className="container">
            <div className="row row-clr top-row">
              <p>
                eClub youth is a non-profit organization registered by the
                government as a welfare society. The membership of eClub youth
                can be obtained only for the students who have completed the
                courses of eClub business college and the entire staff including
                the course consultant officers. The main objective of eClub
                youth is to acquaint the young students who have completed the
                courses of eClub business college to engage in social welfare
                activities at a young age. As the social welfare work done by
                eClub youth so far,
              </p>
              <ul>
                <li>Blood Donation Projects</li>
                <li>
                  Giving alms to children's homes and old people's homes and
                  providing other essential assistance
                </li>
                <li>
                  Giving alms to worshipers in Poya days and cleaning temples
                </li>
                <li>Organizing beach cleaning activities</li>
                <li>Organizing Vesak and Poson dhansalas</li>
                <li>Funeral arrangements for members' families</li>
                <li>Giving alms to pregnant mothers</li>
                <li>Conducting free seminars for school children</li>
              </ul>
            </div>
            <div className="row bottom-row d-flex justify-content-center">
              {youthData && (
                <>
                  {youthData.map((item) => (
                    <div
                      className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-inner youthCard"
                      // style={{ minWidth: "270px" }}
                    >
                      <YouthCard {...item} />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
        {/* <section className="eclub_youth_slider">
          <div className="container inner-container">
            <div className="row  heading-row" data-aos="fade-down">
              <h2>eClub Youth</h2>
            </div>
            {youthCommon && (
              <div
                className="row row-clr slider_eclub_youth"
                data-aos="fade-down"
              >
                <CustomSlider
                  selector=".slider_eclub_youth"
                  settings={sliderEclubYouth}
                >
                  {youthCommon.map((item, id) => (
                    <div className="slider-inner-wrapper">
                      <img
                        src={item}
                        className="img-fluid-youth"
                        alt={"image" + id}
                      />
                    </div>
                  ))}
                  <div className="slider-inner-wrapper">
                    <img
                      src={require("../../assets/images/eclub_youth/eclub_youth-02.png")}
                      className="img-fluid"
                      alt="Image1"
                    />
                  </div>
                  <div className="slider-inner-wrapper">
                    <img
                      src={require("../../assets/images/eclub_youth/eclub_youth-02.png")}
                      className="img-fluid"
                      alt="Image2"
                    />
                  </div>
                  <div className="slider-inner-wrapper">
                    <img
                      src={require("../../assets/images/eclub_youth/eclub_youth-02.png")}
                      className="img-fluid"
                      alt="Image3"
                    />
                  </div>
                  <div className="slider-inner-wrapper">
                    <img
                      src={require("../../assets/images/eclub_youth/eclub_youth-02.png")}
                      className="img-fluid"
                      alt="Image2"
                    />
                  </div>
                  <div className="slider-inner-wrapper">
                    <img
                      src={require("../../assets/images/eclub_youth/eclub_youth-02.png")}
                      className="img-fluid"
                      alt="Image2"
                    />
                  </div>
                  <div className="slider-inner-wrapper">
                    <img
                      src={require("../../assets/images/eclub_youth/eclub_youth-02.png")}
                      className="img-fluid"
                      alt="Image4"
                    />
                  </div>
                  <div className="slider-inner-wrapper">
                    <img
                      src={require("../../assets/images/eclub_youth/eclub_youth-02.png")}
                      className="img-fluid"
                      alt="Image3"
                    />
                  </div>
                </CustomSlider>
              </div>
            )}
          </div>
        </section> */}
      </main>
    </>
  );
};

export default Youth;
