import React, { useContext, useEffect, useState } from "react";
import Video1 from "../../../assets/video/1.mp4";
import eclub1 from "../../../assets/video/eClub1.mp4";
import eclubMobile from "../../../assets/video/heroMobile2.mp4";
import Video12 from "../../../assets/video/1.ogg";
import { NavBarContext } from "../../../assets/contexts/navBarContext";

const Homebanner = () => {
  const [display] = useContext(NavBarContext);
  const [overlayHide, setOverlayHide] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("display", display);
    if (display.hasOwnProperty("overlayHide")) {
      setOverlayHide(display.overlayHide);
    }
  }, [display]);

  return (
    <section className="home-banner-section video-section">
      <div className="bg-video-wrap">
        <video className="videoembed" autoPlay muted loop>
          {screenWidth > 700 && <source src={eclub1} type="video/mp4" />}
          {screenWidth < 700 && <source src={eclubMobile} type="video/mp4" />}
          {/* <source src={eclubMobile} type="video/mp4" /> */}
          {/* <source src={Video12} type="video/ogg" /> */}
        </video>
        <div className={overlayHide ? "" : "overlay"}>
          <div className="row row-clr">
            <div className="container">
              <h2>
                Creating Future <br />
                Entrepreneurs
              </h2>
              <a className="button-type-6" href="courses">
                View Courses
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Homebanner;
