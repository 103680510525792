import React from "react";

const Map = () => {
  return (
    <section className="map_section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <h2>Head Office</h2>
            <p> No. 242, Horana Road, Wekada 12500 Panadura, Sri Lanka</p>
            <iframe
              title="1"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15849.638470214322!2d79.90807499637475!3d6.719767484834543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2477328ff48ad%3A0xeaf0d7c7d7120712!2seClub%20Business%20University!5e0!3m2!1sen!2slk!4v1703144270962!5m2!1sen!2slk"
              style={{
                border: 0,
              }}
            ></iframe>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <h2>City Office</h2>
            <p>651, Galle Road, Nalluruwa 12500 Panadura, Sri Lanka</p>
            <iframe
              title="2"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3962.5396151369087!2d79.910227!3d6.7037946!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae247193ba18231%3A0xbc6b35896f47cae0!2seClub%20Business%20College!5e0!3m2!1sen!2slk!4v1704528614886!5m2!1sen!2slk"
              style={{
                border: 0,
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Map;
