import * as React from "react";
import { motion, useCycle } from "framer-motion";
import { NavLink } from "./Navigation";
import { useNavigate } from "react-router-dom";
import { ArrowRightShort } from "react-bootstrap-icons";

export const variantsLi = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({
  id,
  name,
  to,
  trigger,
  isDropDown,
  dropdownItems,
}: any) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <motion.li
      id={id}
      variants={variantsLi}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      {isDropDown && dropdownItems ? (
        <div className="dropdown-container">
          <span className="dropdown-main" onClick={toggleDropdown}>
            {name} <ArrowRightShort />
          </span>
          {isOpen && (
            <ol className="dropdown">
              {dropdownItems.map((item, index) => (
                <li key={index} className="dropdown-item">
                  <a
                    className="text-placeholder"
                    onClick={(e) => {
                      // e.preventDefault();
                      navigate(`/${item.to}`);
                      trigger();
                    }}
                  >
                    <ArrowRightShort /> {item.name}
                  </a>
                </li>
              ))}
            </ol>
          )}
        </div>
      ) : (
        <a
          className="text-placeholder"
          onClick={(e) => {
            // e.preventDefault();
            navigate(`/${to}`);
            trigger();
          }}
        >
          {name}
        </a>
      )}
    </motion.li>
  );
};
