import React from "react";
import AboutCompany from "./components/aboutCompany";
import CompanyGoals from "./components/companyGoals";
import Directors from "./components/directors";
import CompanyGlance from "./components/companyGlance";
import BreadCrumb from "../../components/BreadCrumb";

const AboutUs = () => {
  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <BreadCrumb mainPage="Home" secondPage="About Us" />
        <AboutCompany />
        <CompanyGoals />
        <Directors />
        {/* <CompanyGlance/> */}
      </main>
    </>
  );
};

export default AboutUs;
