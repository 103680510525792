import React from "react";

const SearchBar = () => {
  return (
    <div
      className="modal fade"
      id="search-popup"
      tabIndex={-1}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      role="dialog"
      aria-labelledby="modalTitleId"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="search"
                name=""
                id="input"
                className="form-control"
                value=""
                required={true}
                pattern=""
                title=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
