import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../pages/home";
import AboutUs from "../pages/aboutUs";
import ContactUs from "../pages/contactUs";
import CourseDetails from "../pages/coursesDetails";
import Courses from "../pages/courses";
import Youth from "../pages/youth";
import News from "../pages/news";
import Media from "../pages/media";
import StudentLife from "../pages/studentLife";
import FreeCourses from "../pages/freeCourses";
import Scholarships from "../pages/scholarships";
import VideoFile from "../pages/media/videoFile";

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/contact_us" element={<ContactUs />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/free_courses" element={<FreeCourses />} />
      <Route path="/scholarships" element={<Scholarships />} />
      <Route path="/life_at_club" element={<StudentLife />} />
      <Route path="/eclub_youth" element={<Youth />} />
      <Route path="/news" element={<News />} />
      <Route path="/media/gallery" element={<Media />} />
      <Route path="/media/video" element={<VideoFile />} />
      <Route path="/course_details/:courseId" element={<CourseDetails />} />
      <Route path="/course_details" element={<CourseDetails />} />
      {/* Add a catch-all route for 404 */}
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default CustomRoutes;
