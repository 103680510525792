import React, { useEffect, useState } from "react";
import CrDetails from "../../../assets/images/courses-detail/courses-detail-2.png";
import VideoModal from "../../../components/videoModal";
import CourseApplication from "../../../components/forms/CourseApply";

const HeaderSection = (propData) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState<any>(propData);
  const [applicationModal, setApplictaionModal] = useState<boolean>(false);

  useEffect(() => {
    if (propData) {
      console.log("hii", propData);
      setData({ ...propData });
    }
  }, [propData]);

  const openModal = () => {
    window.scrollTo(0, 50);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    window.scrollTo(0, 0);
    setModalIsOpen(false);
  };

  return (
    <section className="courses_top_section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 content-wrapper">
            <h2>{data.propData.courseName}</h2>
            <h4>{data.propData.courseId}</h4>
            <p>{data.propData.courseDescription}</p>
            <h4>Who Should Enroll</h4>
            <p>{data.propData.enrollInfo}</p>
            {(data.propData.courseId!='TSG' && data.propData.courseId!='FrMst'  && data.propData.courseId!='PWS') && (<ul className="button-wrapper">
              <li>
                <a
                  className="button-type-4"
                  onClick={() => setApplictaionModal(true)}
                >
                  Apply Now
                </a>
              </li>
              {/* <li>
                <a className="button-type-5" href="#">
                  Pay Online
                </a>
              </li> */}
            </ul>)}
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 hidden-sm text-center">
            <div
              onClick={() => openModal()}
              className="img-fluid pull-right"
              style={{
                cursor: "pointer",
                position: "relative",
              }}
            >
              <img
                src={data.propData.courseThumbNail}
                className="img-fluid pull-right"
                alt="Image2"
                style={{ borderRadius: "20px" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="100"
                  width="100"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  onMouseOver={(e) => (e.currentTarget.style.fill = "red")}
                  onMouseOut={(e) => (e.currentTarget.style.fill = "#fff")}
                  onClick={() => openModal()}
                >
                  <path d="M8 5v14l11-7z" />
                </svg>
              </div>
            </div>
          </div>
          <VideoModal
            videoUrl={data.propData.courseVideo}
            isOpen={modalIsOpen}
            onClose={closeModal}
          />
          <CourseApplication
            isOpen={applicationModal}
            courseCode={data.propData.courseId}
            onClose={() => setApplictaionModal(false)}
          />
        </div>
      </div>
    </section>
  );
};
export default HeaderSection;
