import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

interface AnimatedCounterProps {
  from: number;
  to: number;
  presetString?: string;
}

const Counter: React.FC<AnimatedCounterProps> = ({
  from,
  to,
  presetString,
}) => {
  const controls = useAnimation();
  const [counter, setCounter] = useState(from);
  const counterRef = useRef<HTMLDivElement>(null);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (!entry.isIntersecting) {
      // Reset the counter when the element is out of view
      setCounter(from);
    }
  };

  useEffect(() => {
    controls.start({ opacity: 1, scale: 1, transition: { duration: 0.5 } });
    const interval = setInterval(() => {
      if (counter < to) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 20);

    // Create an IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0, // Trigger when any part of the element is out of view
    });

    // Observe the target element
    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      clearInterval(interval);
      // Disconnect the IntersectionObserver when the component unmounts
      observer.disconnect();
    };
  }, [controls, counter, to]);

  return (
    <motion.h3
      ref={counterRef}
      style={{ opacity: 0, scale: 0.5 }}
      animate={controls}
    >
      {counter.toLocaleString()}
      {presetString}
    </motion.h3>
  );
};

export default Counter;
