import React from "react";
import CustomSlider from "./CustomSlider";
import { eClubYouth } from "../config/sliders";

interface YouthCardProps {
  color: string;
  Heading: string;
  body: string;
  images?: string[];
}

const YouthCard = (props: YouthCardProps) => {
  return (
    <div
      className="row row-clr inner-row"
      style={{ backgroundColor: props.color }}
    >
      <div className="row row-clr heading-row">
        <h2>{props.Heading}</h2>
      </div>
      <div className="col-xs-12 content-wrapper">
        <p>{props.body}</p>
        {props.images && (
          <div className="image-container">
            <CustomSlider selector=".eClubYouth" settings={eClubYouth}>
              {props.images?.map((item, index) => {
                return (
                  <div key={index}>
                    <img src={item} alt="Slide 1" className="imgStyle" />
                  </div>
                );
              })}
            </CustomSlider>
          </div>
        )}
      </div>
    </div>
  );
};

export default YouthCard;
