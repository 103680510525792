import React, { useRef, useState } from "react";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import Logo from "../../../assets/images/logo.svg";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const AnimateMenu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [secondaryOpen, setSecondaryOpen] = useState(false);

  return (
    <motion.div
      id="nav-hider"
      className=" d-flex flex-row justify-content-between"
    >
      <div className="mobile-menu-anime">
        <div className="d-block d-lg-none header-section mobile-menu  navbar  fixed-top container-fluid">
          <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
          >
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  key="background"
                  className="background navbar-collapse"
                  variants={sidebar}
                  animate={isOpen ? "open" : "closed"}
                  style={{
                    overflowY: secondaryOpen ? "auto" : "hidden",
                  }}
                >
                  <Navigation
                    isOpen={secondaryOpen}
                    trigger={() => {
                      toggleOpen();
                      setSecondaryOpen(!secondaryOpen);
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <MenuToggle
              toggle={() => {
                toggleOpen();
                setSecondaryOpen(!secondaryOpen);
              }}
            />
          </motion.nav>
          <a className="navbar-brand" href="/" style={{ float: "inline-end" }}>
            <img src={Logo} className="img-fluid" alt="Image1" />
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default AnimateMenu;
