import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import CommonModal from "../CommonModal";
import { NavBarContext } from "../../assets/contexts/navBarContext";
import axiosInstance from "../../api/axiosConfig";
import { useToast } from "../../assets/contexts/toast";

const formInitialData = {
  student_fname: "",
  student_lname: "",
  mobile: "",
  course: "",
  medium: "",
};

interface CourseApplication {
  isOpen: boolean;
  courseCode?: string;
  onClose: () => void;
}

const CourseApplication = (props: CourseApplication) => {
  const [formData, setFormData] = useState(formInitialData);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [courses, setCourses] = useState([]);
  const { showToast, showSuccessToast, showErrorToast } = useToast();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [display, setDisply] = useContext(NavBarContext);
  const [disabledDrop, setDisableDrop] = useState<boolean>(false);

  useEffect(() => {
    if (props.isOpen) {
      openModal();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (courses.length > 0 && props.courseCode) {
      let filteredObj = courses.find(
        (obj) => obj.course_code === props.courseCode
      );

      if (filteredObj) {
        setFormData({
          ...formData,
          course: filteredObj.id,
        });
        setDisableDrop(true);
      }
    }
  }, [courses, props.courseCode]);

  const openModal = () => {
    setDisply({ ...display, overlayHide: true });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    props.onClose();
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axiosInstance.post("/api/lead/save", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response", response);
      if (response && response.status === 200 && response.data) {
        showSuccessToast(
          response.data.success
            ? response.data.success
            : "Submission successful!"
        );

        setFormData(formInitialData);
        closeModal();
      }
    } catch (error) {
      showErrorToast("Data Submission Failed");
    } finally {
      setLoading(false);
    }
  };

  const getCourses = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/api/courses_list");
      if (response && response.data) {
        setCourses(response.data);
      }
    } catch (error) {
      showErrorToast("Failed to fetch courses. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonModal isOpen={modalIsOpen} onClose={closeModal}>
      <section className="form_section d-flex justify-content-center ">
        {/* {alert && <Alert variant={alert.variant}>{alert.message}</Alert>} */}

        <div className="col-xs-12 col-sm-7 col-md-8 col-lg-8 w-100 right-wrapper">
          <h2>Apply Here</h2>
          <Form id="application" onSubmit={handleSubmit}>
            <div className="row">
              <Form.Group
                className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                controlId="formBasicfName"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="student_fname"
                  required={true}
                  placeholder="Enter first name"
                  onChange={handleChange}
                  value={formData.student_fname}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                controlId="formBasiclName"
              >
                <Form.Label>Last Name </Form.Label>
                <Form.Control
                  type="text"
                  name="student_lname"
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter last name"
                  value={formData.student_lname}
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group
                className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                controlId="formBasicMobile"
              >
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  name="mobile"
                  required={true}
                  placeholder="Enter mobile"
                  value={formData.mobile}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                controlId="formBasicCourse"
              >
                <Form.Label>Courses</Form.Label>
                <Form.Select
                  name="course"
                  onChange={handleChange}
                  value={formData.course}
                  disabled={disabledDrop}
                >
                  <option value="">Select Course</option>
                  {courses.map((course) => (
                    <option key={course.id} value={course.id}>
                      {course.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group
                className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                controlId="formBasicMedium"
              >
                <Form.Label>Medium</Form.Label>
                <Form.Select
                  name="medium"
                  onChange={handleChange}
                  value={formData.medium}
                  required
                >
                  <option value="">Select Medium</option>
                  <option value="sinhala">Sinhala</option>
                  <option value="tamil">Tamil</option>
                  <option value="english">English</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </CommonModal>
  );
};

export default CourseApplication;
