import React, { useState } from "react";
import CustomSlider from "../../components/CustomSlider";
import { newsSlider, ourCoursesSlider } from "../../config/sliders";
import NewsImg from "../../assets/images/news/daily-news-head.jpg";
import NewsImg3 from "../../assets/images/news/news-03.png";
import NewsImg4 from "../../assets/images/news/news-04.png";
import NewsImg5 from "../../assets/images/news/news-05.png";
import CommonModal from "../../components/CommonModal";
import BreadCrumb from "../../components/BreadCrumb";

const News = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const slides = [
    {
      backgroundImage: 'url("assets/images/news/news-01.png")',
      title: "eClub Gala Night 23’",
      breadcrumb: [
        { link: "index.html", text: "Home" },
        { active: true, text: "News & Events" },
      ],
    },
    // Add more slides as needed
  ];
  const carouselItems = [
    {
      text: "First slide",
      label: "First slide label",
      description: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
      imageUrl: "assets/images/news/news-02.png",
    },
    {
      text: "Second slide",
      label: "Second slide label",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageUrl: "assets/images/news/news-02.png",
    },
    {
      text: "Third slide",
      label: "Third slide label",
      description:
        "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
      imageUrl: "assets/images/news/news-02.png",
    },
  ];
  return (
    <>
      <h1 hidden>SEO</h1>
      <section className="news_banner_section">
        <div className=" row row-clr news-slider">
          <CustomSlider selector=".news-slider" settings={newsSlider}>
            <div className="slider-inner ">
              <div
                className="row row-clr slider-inner padding-news"
                style={{
                  backgroundImage: `url(${NewsImg})`,
                }}
              >
                <div className="container">
                  <ol className="breadcrumb breadcrumb-type-1">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li className="active">&ensp;/&ensp;News & Events</li>
                  </ol>
                  {/* <BreadCrumb mainPage="Home" secondPage="News & Events" /> */}

                  <div className="row row-clr heading-row">
                    <h2>Daily News</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-inner">
              <div
                className="row row-clr slider-inner"
                style={{
                  backgroundImage: `url(${NewsImg})`,
                }}
              >
                <div className="container">
                  <ol className="breadcrumb breadcrumb-type-1">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li className="active">News & Events</li>
                  </ol>
                  <div className="row row-clr heading-row">
                    <h2>Daily News</h2>
                  </div>
                </div>
              </div>
            </div>
          </CustomSlider>
        </div>
        {/* <div className="row row-clr our_courses_slider" data-aos="fade-up">
          <CustomSlider
            selector=".our_courses_slider"
            settings={ourCoursesSlider}
          >
            <div className="slider-inner">
              <div
                className="contnet-wrapper"
                style={{
                  backgroundImage: `url('assets/images/Our-Courses/1.png')`,
                }}
              >
                <h3>ebay </h3>
                <h4>Selling Guide</h4>
                <div className="row row-clr inner-row">
                  <img
                    src="assets/images/Our-Courses/1-1.png"
                    className="img-responsive"
                    alt="Image"
                  />
                  <p>Selling Guide</p>
                  <a className="button-type-3" href="/">
                    Join Now
                  </a>
                </div>
              </div>
            </div>
          </CustomSlider>
        </div> */}
      </section>
      <section className="news_bottom_section">
        <div className="container">
          <div className="row row-clr news-type-3">
            <div className="row row-clr eq_align card-area">
              <div
                className="col-xs-12 col-sm-5 col-md-4 col-lg-4 image-wrapper"
                style={{
                  backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/event-04-08.jpeg)`,
                }}
              ></div>
              <div className="col-xs-12 col-sm-7 col-md-8 col-lg-8 content-wrapper pull-right">
                <label className="event-label">Event</label>
                <h2>Certificate In IT New Content Added Computer Hardware & Laptop Repair Parts.</h2>
                
               <p>ඔබේ හෙට වෙනුවෙන් කරන හොඳම ආයෝජනය අද ඉගෙනීමයි.

පරිගණකය හසුරුවා ගැනීම , මෘදුකාංග භාවිතය සහ තොරතුරු තාක්ෂණය සමඟ මුහුවීමේ හැකියාව තුළින් වැඩ ලෝකයට පිවිසීමට බලාපොරොත්තු වන ඔබගේ සිහින යාථාර්ථයක් බවට පත් කරනු ඇත.

තොරතුරු තාක්ෂණවේදය පිළිබඳ සහතික පත්‍ර Online පාඨමාලාවේ මීළඟ කණ්ඩායම මැයි 8 වන දින ආරම්භ වේ.

මූලික දැනුමක් අවශ්‍ය නොවන අතර වසර ගණනාවක් පළපුරුදු සහිත දිවයිනේ දක්ෂ ඇදුරු මඬුල්ලක් විසින් මෙහෙය වන මෙම පාඨමාලාව සඳහා පරිගණක දැනුම අවශ්‍ය ඕනෑම අයෙකුට අයදුම් කළ හැකිය.

විස්තර දැනගැනීමට අදම අප සමඟ සම්බන්ධ වන්න.</p>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news_top_section">
        <div className="container">
          <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 news-type-1">
              <div
                className="row row-clr inner-row"
                // style={{
                //   backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news.jpg)`,
                // }}
              >
                <label className="event-label">Event</label>
                 <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/event-04-08.jpeg" />
              
              
              
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 news-type-1">
      
              <div
                className="row row-clr inner-row"
                // style={{
                //   backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news.jpg)`,
                // }}
              >
                <label className="event-label">Event</label>
                <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/event-05-03.jpeg" />
       
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 news-type-1">
      
      <div
        className="row row-clr inner-row"
        // style={{
        //   backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news.jpg)`,
        // }}
      >
        <label className="event-label">Event</label>
        <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/event-05-02.jpeg" />

      </div>
    </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 news-type-1">
              <div
                className="row row-clr inner-row"
                // style={{
                //   backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news.jpg)`,
                // }}
              >
                <label className="event-label">Event</label>
                <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news-04-25.jpeg" />

              
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 news-type-1">
              <div className="row row-clr inner-row">
                <label className="news-label">News</label>
                <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/daily-news-head-2-2.jpg" />

                <div className="content-wrappe new_body">
                  <h3>Daily News</h3>
                  <p>
                    Team building, leadership development, and personal growth.
                    Participants engage in a variety of outdoor activities,
                    problem-solving challenges, and team exercises to build
                    skills like communication, leadership, teamwork, and
                    problem-solving.
                  </p>
                  <a className="read-more-link" onClick={() => openModal()}>
                    Read More
                  </a>
                  <CommonModal isOpen={modalIsOpen} onClose={closeModal}>
                    <div>
                      <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/daily-news-head-2-2.jpg" />
                    </div>
                  </CommonModal>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 news-type-1">
              <div
                className="row row-clr inner-row"
                // style={{
                //   backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news.jpg)`,
                // }}
              >
                <label className="event-label">Event</label>
                <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news.jpg" />

                <div className="content-wrappe new_body">
                  <h3>Goal Setting 2024</h3>
                  <p>
                    A program called Goal Setting will be held on December 21,
                    2023 for all course consultant officers to discuss the
                    marketing forecast for the year 2023 and the marketing plan
                    for the year 2024. Nearly 500 course consultant officers are
                    going to participate in this program.
                  </p>
                </div>
              </div>
            </div>
         
            {/* <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 news-type-2">
              <div className="row row-clr inner-row">
                <div
                  className="image-wrapper"
                  style={{
                    backgroundImage: `url(${NewsImg4})`,
                  }}
                >
                  <label className="event-label">Event</label>
                </div>
                <div className="content-wrappe">
                  <h3>Crypto Trading Guide Awareness</h3>
                  <p>
                    Crypto Trading Guide Awareness Tamil Language September 17
                    Join Zoom Meeting
                    <a
                      href="https://us02web.zoom.us/j/81512673428..."
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      https://us02web.zoom.us/j/81512673428...
                    </a>
                    Meeting ID: 815 1267 3428 Passcode: 755080
                  </p>
                  <a className="read-more-link" href="/">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 news-type-2">
              <div className="row row-clr inner-row">
                <div
                  className="image-wrapper"
                  style={{
                    backgroundImage: `url(${NewsImg4})`,
                  }}
                >
                  <label className="news-label">News</label>
                </div>
                <div className="content-wrappe">
                  <h3>Crypto Trading Guide Awareness</h3>
                  <p>
                    Crypto Trading Guide Awareness Tamil Language September 17
                    Join Zoom Meeting
                    <a
                      href="https://us02web.zoom.us/j/81512673428..."
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      https://us02web.zoom.us/j/81512673428...
                    </a>
                    Meeting ID: 815 1267 3428 Passcode: 755080
                  </p>
                  <a className="read-more-link" href="/">
                    Read More
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="news_bottom_section">
        <div className="container">

          <div className="row row-clr news-type-3">
            <div className="row row-clr eq_align card-area">
              <div
                className="col-xs-12 col-sm-5 col-md-4 col-lg-4 image-wrapper"
                style={{
                  backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/galanightHome.jpg`,
                }}
              ></div>
              <div className="col-xs-12 col-sm-7 col-md-8 col-lg-8 content-wrapper pull-right">
                <label className="event-label">Event</label>
                <h2>eClub Gala Night 2023</h2>
                <h5>
                  An annual Get together of all course consulting officers and
                  an appreciation program for high performed course consulting
                  officers was held on 20 November 2023 in kithulgala.
                </h5>
              </div>
            </div>
          </div>
          <div className="row row-clr news-type-3 ">
            <div className="row row-clr eq_align card-area">
              <div
                className="col-xs-12 col-sm-5 col-md-4 col-lg-4 image-wrapper"
                style={{
                  backgroundImage: `url(https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/news-2.jpg)`,
                }}
              ></div>
              <div className="col-xs-12 col-sm-7 col-md-8 col-lg-8 content-wrapper pull-right">
                <label className="event-label">Event</label>
                <h2>Get together 2023</h2>
                <h4>
                  "Let's gather and make memories together! Join us for a
                  delightful get-together."
                </h4>
                <p>
                  The annual reunion for the year 2023 will be held on Monday,
                  18 December 2023 from 9.00 am to 4.00 pm, with the
                  participation of all staff members and lecturers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default News;
