import React, { useEffect, useState } from "react";
// import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Header } from "./components/layouts/header";
import { Footer } from "./components/layouts/footer";

import { BrowserRouter } from "react-router-dom";
import CustomRoutes from "./config/Routes";
import ScrollToTop from "./config/hooks/scrollTopHook";
import { NavBarContext } from "./assets/contexts/navBarContext";
import { ToastProvider } from "./assets/contexts/toast";
import { LoaderContextProvider } from "./assets/contexts/loaderContextProvider";
import Loader from "./components/Loader";

function App() {
  const [display, setDisply] = useState<any>({});

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <BrowserRouter>
        <LoaderContextProvider>
          <ScrollToTop />
          <Loader />
          <NavBarContext.Provider value={[display, setDisply]}>
            <div>
              <ToastProvider>
                <Header />
                <main>
                  <CustomRoutes />
                </main>
                <Footer />
              </ToastProvider>
            </div>
          </NavBarContext.Provider>
        </LoaderContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
