import React, { useState, useEffect, useMemo } from "react";
import useMeasure from "react-use-measure";
import { useTransition, a } from "@react-spring/web";
import shuffle from "lodash.shuffle";
import LazyLoad from "react-lazy-load";
import useMedia from "./useMedia";
import data from "./data";
import Modal from "react-modal";
import { useSpring, animated } from "react-spring";
import { useMediaQuery } from "react-responsive";

const filters = ["All", "Class Rooms", "IT Rooms", "Trading Rooms"];

// Set the root element for the modal
Modal.setAppElement("#root");

const Masonry = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Adjust the max width as needed
  // Hook1: Tie media queries to the number of columns
  const columns = useMedia(
    ["(min-width: 1500px)", "(min-width: 1000px)", "(min-width: 600px)"],
    [4, 3, 2],
    1
  );
  // Hook2: Measure the width of the container element
  const [ref, { width }] = useMeasure();
  // Hook3: Hold items
  const [items, setItems] = useState(data);
  const [filter, setFilter] = useState("All");

  const shuffleItems = () => {
    setItems(shuffle(items));
  };

  useEffect(() => {
    shuffleItems();
  }, [filter]); // Trigger shuffle when the filter changes

  const filteredItems = useMemo(() => {
    if (filter === "All") {
      return items;
    } else {
      return items.filter((item) => item.category === filter);
    }
  }, [filter, items]);

  // Hook5: Form a grid of stacked items using width & columns we got from hooks 1 & 2
  const [heights, gridItems] = useMemo(() => {
    let heights = new Array(columns).fill(0);
    let gridItems = filteredItems.map((child, i) => {
      const column = heights.indexOf(Math.min(...heights));
      const x = (width / columns) * column;
      const y = (heights[column] += child.height / 2) - child.height / 2;

      return {
        ...child,
        x,
        y,
        width: (width * 1.05) / columns,
        height: child.height / 2,
      };
    });
    return [heights, gridItems];
  }, [columns, filteredItems, width]);

  // Hook6: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(gridItems, {
    key: (item) => item.key,
    from: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 0 }),
    enter: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 1 }),
    update: ({ x, y, width, height }) => ({ x, y, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });

  // State for handling modal visibility
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to open the modal with the selected image
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Animation for the modal
  const modalTransition = useSpring({
    opacity: modalIsOpen ? 1 : 0,
    transform: modalIsOpen ? "translateY(0%)" : "translateY(-20%)",
  });

  return (
    <div className="media_animation_grid">
      <div className="filters button-group js-radio-button-group device-type">
        {filters.map((f) => (
          <button
            key={f}
            onClick={() => {
              setFilter(f);
              shuffleItems(); // Trigger shuffle when a filter button is clicked
            }}
            disabled={filter === f}
            className={filter === f ? "is-checked" : ""}
          >
            {f}
          </button>
        ))}
      </div>
      <div
        ref={ref}
        className={"list"}
        style={{ height: Math.max(...heights) }}
      >
        {transitions((style, item) => (
          <a.div
            key={item.key}
            style={style}
            onClick={() =>
              !isMobile &&
              openModal(`${item.css}?auto=compress&dpr=2&h=500&w=500`)
            }
          >
            <div
              className="image"
              style={{
                backgroundImage: `url(${item.css}?auto=compress&dpr=2&h=500&w=500)`,
                backgroundSize: "cover",
                width: "100%",
                height: "282px",
                borderRadius: "15px",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
              }}
            />
          </a.div>
        ))}
      </div>

      {/* Modal component with animation */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "brightness(0.5)",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            background: "transparent",
            overflow: "visible",
          },
          backdropFilter: "brightness(0.5)",
        }}
      >
        <animated.div style={modalTransition}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected Image1"
              style={{ width: "100%", height: "auto", maxHeight: "90vh" }}
            />
          )}
        </animated.div>
      </Modal>
    </div>
  );
};

export default Masonry;
