import React from "react";
import Banner from "./Components/banner";
import Form from "./Components/form";
import Map from "./Components/map";
import BreadCrumb from "../../components/BreadCrumb";

const ContactUs = () => {
  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <BreadCrumb mainPage="Home" secondPage="Contact Us"/>
        <Banner />
        <Form />
        <Map />
      </main>
    </>
  );
};

export default ContactUs;
