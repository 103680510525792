import React, { useState } from "react";
import VideoModal from "../../../components/videoModal";

const CoursesGroup = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openModalId, setOpenModalId] = useState(null);
  // const [data, setData] = useState<any>(propData);

  const openModal = (videoId) => {
    window.scrollTo(0, 50);
    setModalIsOpen(true);
    setOpenModalId(videoId);
  };

  const closeModal = () => {
    window.scrollTo(0, 0);
    setModalIsOpen(false);
    setOpenModalId(null);
    document.body.style.overflow = "visible";
    const isMobile = window.innerWidth <= 767;
    const windowidth = window.innerWidth;
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    document.body.style.paddingTop =
      isMobile || (isLandscape && windowidth < 1000) ? "" : "140px";

    // document.body.style.paddingTop = "140px";
  };

  return (
    <div className="free_course container mt-4">
      <div className="row row-clr heading-row text-center mb-4">
        <h2>Pinterest</h2>
        <p className="pt-1">
          Pinterest is a visual discovery and bookmarking platform where users
          explore and save ideas for various interests. It acts as a digital
          pinboard, allowing individuals to discover, curate, and share content
          like images, recipes, and inspiration. Users create themed boards to
          organize their ideas, "pinning" images or content onto them. It's a
          versatile tool for finding creative inspiration, planning projects,
          and discovering new trends. Whether for personal hobbies, event
          planning, or business promotion, Pinterest serves as a visual search
          engine, fostering a community of users sharing and discovering
          visually appealing content.
        </p>
        <p>
          In this free course, the online business community is given a simple
          step-by-step guide on how to promote themselves by using pinterest
        </p>
      </div>
      <div className="row">
        {freeCourses.map((course) =>
          course.categoryInfo.map((session) => (
            <div key={session.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <div className="course_card shadow p-3 mb-5 bg-body rounded card">
                <div className="card-body p-0">
                  <div
                    onClick={() => openModal(session.id)}
                    className="img-fluid pull-right"
                    style={{
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <h6 className="card-title">{session.title}</h6>

                    <VideoThumbnail videoUrl={session.videoUrl} />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="100"
                        width="100"
                        viewBox="0 0 24 24"
                        fill="#fff"
                        onMouseOver={(e) =>
                          (e.currentTarget.style.fill = "red")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.fill = "#fff")
                        }
                        onClick={() => openModal(session.id)}
                      >
                        <path d="M8 5v14l11-7z" />
                      </svg>
                    </div>
                  </div>
                  {openModalId === session.id && modalIsOpen && (
                    <VideoModal
                      id={session.id}
                      videoUrl={session.videoUrl}
                      isOpen={modalIsOpen}
                      onClose={closeModal}
                    />
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CoursesGroup;

const VideoThumbnail = ({ videoUrl }) => {
  // Extract video ID from YouTube URL
  const videoId = videoUrl.split("v=")[1];

  // Construct the thumbnail URL
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

  return (
    <img src={thumbnailUrl} alt="Video Thumbnail" className="card-img-top" />
  );
};

const freeCourses = [
  {
    id: 1,
    category: "pinterest",
    categoryName: "Pinterest",
    categoryInfo: [
      {
        id: 1,
        title: "Session 01",
        videoUrl: "https://www.youtube.com/watch?v=z3QLnZZ2pzg",
      },
      {
        id: 2,
        title: "Session 02",
        videoUrl: "https://www.youtube.com/watch?v=u4490OijkxE",
      },
      {
        id: 3,
        title: "Session 03",
        videoUrl: "https://www.youtube.com/watch?v=FGqvGpqGvTA",
      },
      {
        id: 4,
        title: "Session 04",
        videoUrl: "https://www.youtube.com/watch?v=5rmmA-ebz-c",
      },
      {
        id: 5,
        title: "Session 05",
        videoUrl: "https://www.youtube.com/watch?v=CDzTzmGiOck",
      },
    ],
  },
];
