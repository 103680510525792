import React from "react";
import CoursesGroup from "./components/coursesGroup";
import BreadCrumb from "../../components/BreadCrumb";

const Courses = () => {
  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <BreadCrumb mainPage="Home" secondPage="Courses" />
        <CoursesGroup />
      </main>
    </>
  );
};

export default Courses;
