import React, { useState } from "react";
import VideoModal from "../../components/videoModal";
import BreadCrumb from "../../components/BreadCrumb";
import Media1 from "../../assets/images/media-page/media-1.svg";

const VideoFile = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openModalId, setOpenModalId] = useState(null);
  // const [data, setData] = useState<any>(propData);

  const openModal = (videoId) => {
    window.scrollTo(0, 500);
    setModalIsOpen(true);
    setOpenModalId(videoId);
  };

  const closeModal = () => {
    window.scrollTo(0, 0);
    setModalIsOpen(false);
    setOpenModalId(null);
    document.body.style.overflow = "visible";
    // document.body.style.paddingTop = "140px";
  };

  return (
    <>
      <h1 hidden>SEO</h1>
      <main>
        <BreadCrumb mainPage="Home" secondPage="Media / Video" />
        <section className="media_image_section">
          <div className="container">
            <div className="row row-clr heading-row">
              <h2>
                <img src={Media1} className="img-fluid" alt="Image1" />
              </h2>
            </div>
            <div className="row filter-wrapper">
              <div className="free_course container mt-4">
                <div className="row">
                  {freeCourses.map((course) =>
                    course.categoryInfo.map((session) => (
                      <div
                        key={session.id}
                        className="col-lg-3 col-md-4 col-sm-6 mb-4"
                      >
                        <div className="course_card shadow p-3 mb-5 bg-body rounded card">
                          <div className="card-body p-0">
                            <div
                              onClick={() => openModal(session.id)}
                              className="img-fluid pull-right"
                              style={{
                                cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              <h6 className="card-title text-with-ellipsis">
                                {session.title}
                              </h6>

                              <VideoThumbnail videoUrl={session.videoUrl} />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  cursor: "pointer",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="100"
                                  width="100"
                                  viewBox="0 0 24 24"
                                  fill="#fff"
                                  onMouseOver={(e) =>
                                    (e.currentTarget.style.fill = "red")
                                  }
                                  onMouseOut={(e) =>
                                    (e.currentTarget.style.fill = "#fff")
                                  }
                                  onClick={() => openModal(session.id)}
                                >
                                  <path d="M8 5v14l11-7z" />
                                </svg>
                              </div>
                            </div>
                            {openModalId === session.id && modalIsOpen && (
                              <VideoModal
                                id={session.id}
                                videoUrl={session.videoUrl}
                                isOpen={modalIsOpen}
                                onClose={closeModal}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>{" "}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default VideoFile;

const VideoThumbnail = ({ videoUrl }) => {
  // Extract video ID from YouTube URL
  const videoId = videoUrl.split("v=")[1];

  // Construct the thumbnail URL
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

  return (
    <img src={thumbnailUrl} alt="Video Thumbnail" className="card-img-top" />
  );
};

const freeCourses = [
  {
    id: 1,
    category: "vidoes",
    categoryName: "Videos",
    categoryInfo: [
      {
        id: 1,
        title:
          "eClub අපේ අවුරුදු",
        videoUrl: "https://www.youtube.com/watch?v=lDqfHWWQJj4",
      },
      {
        id: 1,
        title:
          "𝟓𝐭𝐡 𝐀𝐍𝐍𝐈𝐕𝐄𝐑𝐒𝐀𝐑𝐘 𝐂𝐄𝐋𝐄𝐁𝐑𝐀𝐓𝐈𝐎𝐍 𝟐𝟎𝟐𝟑 | 𝐴 𝑃𝐿𝐴𝐶𝐸 𝑇𝑂 𝑇𝑈𝑅𝑁 𝐷𝑅𝐸𝐴𝑀𝑆 𝐼𝑁𝑇𝑂 𝑅𝐸𝐴𝐿𝐼𝑇𝑌",
        videoUrl: "https://www.youtube.com/watch?v=MZ2Oj6BsAAo",
      },
      {
        id: 2,
        title: "𝗲𝗰𝗹𝘂𝗯 𝗖𝗿𝘆𝗽𝘁𝗼𝗻𝗲𝘅 𝟮𝟬𝟮𝟯",
        videoUrl: "https://www.youtube.com/watch?v=QE_i9SqGqqs",
      },
      {
        id: 3,
        title: "eClub Drive in Concert Featuring YAKA Crew with DJ DIXXA.",
        videoUrl: "https://www.youtube.com/watch?v=QE_i9SqGqqs",
      },
      {
        id: 4,
        title:
          "𝐞𝐂𝐥𝐮𝐛 𝐁𝐮𝐬𝐢𝐧𝐞𝐬𝐬 𝐂𝐨𝐥𝐥𝐞𝐠𝐞 -නව ලොවට අතහිත 𝐒𝐜𝐡𝐨𝐥𝐚𝐫𝐬𝐡𝐢𝐩 𝐏𝐫𝐨𝐠𝐫𝐚𝐦 𝟐𝟎𝟐𝟐",
        videoUrl: "https://www.youtube.com/watch?v=_-1dOQlpUfk",
      },
      {
        id: 5,
        title:
          "eBay Drop Shipping/ Direct Shipping TV Derana interview with Mr. Dulaj Kumara Director of eClub",
        videoUrl: "https://www.youtube.com/watch?v=jjjTEt0jX9Q",
      },
    ],
  },
];
