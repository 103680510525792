export const courses = [
  {
    categoryId: 1,
    categoryName: "Online Businesses",
    categoryData: [
      {
        id: 1,
        courseName: "eBay",
        courseCode: "ESG",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-blue.png",

        subHeading: "Selling Guide",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/ebay-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 13,
        courseName: "Freelance Masters",
        courseCode: "FrMst",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-green.png",

        subHeading: "Freelance Masters",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/Freelance_Masters_Logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 35,
        courseName: "Crypto",
        courseCode: "CTG",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-lt-blue.png",

        subHeading: "Trading Guide",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/crypto-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
    ],
  },
  {
    categoryId: 2,
    categoryName: "Personal Development",
    categoryData: [
      {
        id: 7,
        courseName: "PWS",
        courseCode: "PWS",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-lt-green.png",

        subHeading: "Passionpreneurs Workshop",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/passionpreneurs-workshops-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 4,
        courseName: "Success Guide",
        courseCode: "TSG",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-lt-orange.png",

        subHeading: "Success Guide",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/the-success-guide-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
    ],
  },
  {
    categoryId: 3,
    categoryName: "It & Multimedia",
    categoryData: [
      {
        id: 9,
        courseName: "Certificate in IT",
        courseCode: "CIT",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-lt-pink.png",

        subHeading: "CIT",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/cit-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 41,
        courseName: "Computer Application Assistant",
        courseCode: "ICT",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-orange.png",

        subHeading: "ICT",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/cit-nvq.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 12,
        courseName: "Graphic Designing",
        courseCode: "certificate-course",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-pink.png",

        subHeading: "Design",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/graphic-design.png",
        logoAvailable: true,
        bodyLabel: "",
      },
    ],
  },
  {
    categoryId: 4,
    categoryName: "Language Courses",
    categoryData: [
      {
        id: 11,
        courseName: "Japanese",
        courseCode: "JLPT N5",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-red.png",

        subHeading: "JLPT N5",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/japanese-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 45,
        courseName: "Japanese",
        courseCode: "JFT",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-yellow.png",

        subHeading: "JFT",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/japanese-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 37,
        courseName: "English",
        courseCode: "ACIE",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-lt-pink.png",

        subHeading: "ACEC",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/english-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 43,
        courseName: "IELTS",
        courseCode: "IELTS",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-red.png",

        subHeading: "IELTS",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/ielts-logo.png",
        logoAvailable: true,
        bodyLabel: "",
      },
      {
        id: 36,
        courseName: "Korean",
        courseCode: "EPS TOPIK",
        backgroundImage:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_arts/card-lt-blue.png",

        subHeading: "EPSTOPIK",
        logoUrl:
          "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/course_picture/logo/korean.png",
        logoAvailable: true,
        bodyLabel: "",
      },
    ],
  },
];
