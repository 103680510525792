import axios from "axios";

const accessToken = "$2y$10$S3W2WSR9sk5cf0yEqR1Rf.oZGkvhUu46idzux2QLMTTzt3m2IHIWS";

const axiosInstance = axios.create({
  baseURL: "https://www.eclub.lk", // Set your API base URL
  headers: {
    'access_token': '$2y$10$S3W2WSR9sk5cf0yEqR1Rf.oZGkvhUu46idzux2QLMTTzt3m2IHIWS', 
    'Content-Type': 'application/json', 
    'Cookie': 'wtk_s=mjjt6hojcad03nkhfog95lcb94', 
  },
});

export default axiosInstance;
