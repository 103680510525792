import React, { useContext, useEffect, useState } from "react";
import Search from "../../assets/images/header/search.svg";
import Logo from "../../assets/images/logo_white.svg";

import CourseApplication from "../forms/CourseApply";

const TopLayer = () => {
  const [applicationModal, setApplictaionModal] = useState<boolean>(false);
  return (
    <>
      <header className="row row-clr">
        <div className="container d-flex flex-wrap justify-content-center top-container">
          <a
            className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none"
            href="index.html"
          >
            <img
              src={Logo}
              style={{ width: "170px", height: "67px" }}
              className="img-fluid"
              alt="Image1"
            />
          </a>
          <div className="col-12 col-lg-auto mb-lg-0 right">
            <ul className="">
              <li className="active">
                <a data-bs-toggle="modal" data-bs-target="#search-popup">
                  <img src={Search} className="img-fluid" alt="Image2" />
                </a>
              </li>
              <li>
                <a href="https://www.eclub.lk/login?l=lms">LMS</a>
              </li>
              <li>
                <a
                  className="button-type-2"
                  onClick={() => setApplictaionModal(true)}
                >
                  Apply Now
                </a>
              </li>
              <li>
                <a
                  className="button-type-1"
                  href="https://www.eclub.lk/login?l=student"
                >
                  Log In
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {applicationModal && (
        <CourseApplication
          isOpen={applicationModal}
          onClose={() => {
            setApplictaionModal(false);
          }}
        />
      )}
    </>
  );
};

export default TopLayer;
