import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface LoaderContextType {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const LoaderContext = createContext<LoaderContextType>({
  loading: false,
  setLoading: () => {},
});

interface LoaderContextProviderProps {
  children: ReactNode;
}

export const LoaderContextProvider = ({
  children,
}: LoaderContextProviderProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const contextValue: LoaderContextType = {
    loading,
    setLoading,
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = () => {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error(
      "useLoaderContext must be used within a LoaderContextProvider"
    );
  }

  return context;
};
