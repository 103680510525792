import React, { useEffect } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface CustomSliderProps {
  selector?: string;
  settings: Settings;
  children: React.ReactNode;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  selector,
  settings,
  children,
}) => {
  useEffect(() => {
    // Initialize your slider here if needed
    // Example: $(selector).slick(settings);

    // Ensure to destroy the slider when the component unmounts
    return () => {
      // Example: $(selector).slick('unslick');
    };
  }, [selector, settings]);

  return <Slider {...settings}>{children}</Slider>;
};

export default CustomSlider;
