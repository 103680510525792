import React from "react";
import CustomSlider from "../../../components/CustomSlider";
import { logoSlider } from "../../../config/sliders";

const Partners = () => {
  return (
    <section className="our_partners_section">
      <div className="container">
        <div className="row  heading-row" data-aos="fade-up">
          <h2>Our Partners</h2>
        </div>
        <div className="row row-clr" data-aos="fade-down">
          <div className="logo-slider">
            <CustomSlider selector=".logo-slider" settings={logoSlider}>
              <div className="logo-slide">
                <img
                  src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/our-partners/01.tvec.svg"
                  className="img-responsive logo-slider-image"
                  alt="tvec"
                />
              </div>
              <div className="logo-slide">
                <img
                  src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/our-partners/02.sampath_bank.svg"
                  className="img-responsive logo-slider-image"
                  alt="sampath"
                />
              </div>
              <div className="logo-slide">
                <img
                  src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/our-partners/03.+commercial_bank.svg"
                  className="img-responsive logo-slider-image"
                  alt="commercial"
                />
              </div>
              <div className="logo-slide">
                <img
                  src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/our-partners/04.epic_waves.svg"
                  className="img-responsive logo-slider-image"
                  alt="epic"
                />
              </div>
            </CustomSlider>
          </div>
          {/* <!-- logo-slider-image --> */}
        </div>
      </div>
    </section>
  );
};

export default Partners;
