import React from "react";
import About2 from "../../../assets/images/about-us-page/about-2.svg";
import About3 from "../../../assets/images/about-us-page/about-3.svg";
import About4 from "../../../assets/images/about-us-page/about-4.svg";

const CompanyGoals = () => {
  return (
    <section className="compnay_goals_section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 content-box">
            <img src={About2} className="img-fluid" alt="Image3" />
            <div className="content-wrapper">
              <h2>Our Vision</h2>
              <h4>
                Deliver the most innovative educational curriculums to create
                successful entrepreneurs in the global context while thriving
                the Sri Lankan economy and uplifting the living standards of our
                stakeholders
              </h4>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 content-box">
            <img src={About3} className="img-fluid" alt="Image2" />
            <div className="content-wrapper">
              <h2>Our Mission</h2>
              <h4>
                Committed to create exceptional business personalities through
                up-to-date skill development, seeking most effective business
                opportunities as entrepreneurs and enhance the performance level
                which is capable to cater to the global context while making a
                significant contribution to the Sri Lankan economy and social
                standards through a regular innovation on vocational educational
                systems and deliver an exclusive and ultimate value to our
                students
              </h4>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 content-box">
            <img src={About4} className="img-fluid" alt="Image1" />
            <div className="content-wrapper">
              <h2>Our Values</h2>
              <h4>
                Leadership in all levels , Positive thinking even in worst
                situation ,Innovative mindset, Shared success, Giving back to
                the society
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CompanyGoals;
