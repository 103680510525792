import React, { useState } from "react";
import Call from "../../../assets/images/contact/Contact-call.svg";
import Mail from "../../../assets/images/contact/Contact-mail.svg";
import FB from "../../../assets/images/contact/Contact-f.png";
import Insta from "../../../assets/images/contact/Contact-I.png";
import Linkd from "../../../assets/images/contact/Contact-L.png";
import Twit from "../../../assets/images/contact/Contact-t.png";

import { Button, Form, Spinner } from "react-bootstrap";
import axiosInstance from "../../../api/axiosConfig";
import { useToast } from "../../../assets/contexts/toast";

const formInitialData = {
  name: "",
  email: "",
  mobile: "",
  subject: "",
  message: "",
};

const ContactForm = () => {
  const [formData, setFormData] = useState(formInitialData);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const { showToast, showSuccessToast, showErrorToast } = useToast();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axiosInstance.post("/api/contact/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response", response);
      if (response && response.status === 200 && response.data) {
        showSuccessToast(
          response.data.message
            ? response.data.message
            : "Submission successful!"
        );

        setFormData(formInitialData);
      }
    } catch (error) {
      showErrorToast("Data Submission Failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="form_section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4 left-wrapper">
            <h2>Contact Us</h2>
            <h4>Call us now, Win your life</h4>
            <ul className="contact-details">
              <li>
                <a href="tel:+94755621621">
                  <img src={Call} className="img-fluid" alt="Image1" />
                  0755 621 621
                </a>
              </li>
              <li>
                <a href="mailto:info@eclub.lk">
                  <img src={Mail} className="img-fluid" alt="Image3" />
                  info@eclub.lk
                </a>
              </li>
            </ul>
            <h4>Connect With us on Social media</h4>
            <ul className="social-media-wrapper">
              <li>
                <a
                  href="https://www.facebook.com/eclub.lk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FB} className="img-responsive" alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/eclub_international/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Insta} className="img-responsive" alt="Instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/eClub_LK"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Twit} className="img-responsive" alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/eclub-business-college/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Linkd} className="img-responsive" alt="Linkedin" />
                </a>
              </li>
            </ul>
            <div className="clearfix"></div>
          </div>
          <div className="col-xs-12 col-sm-7 col-md-8 col-lg-8 right-wrapper">
            <h2>Send us An Email</h2>
            <Form id="contactUs" onSubmit={handleSubmit}>
              <div className="row">
                <Form.Group
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                  controlId="formBasicName"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    required={true}
                    placeholder="Enter name"
                    onChange={handleChange}
                    value={formData.name}
                  />
                </Form.Group>
                <Form.Group
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                  controlId="formBasicPhone"
                >
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    onChange={handleChange}
                    required={true}
                    placeholder="Enter phone number"
                    value={formData.mobile}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    required={true}
                    placeholder="Enter email"
                    value={formData.email}
                  />
                </Form.Group>
                <Form.Group
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                  controlId="formBasicSubject"
                >
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    name="subject"
                    onChange={handleChange}
                    required={true}
                    placeholder="Enter subject"
                    value={formData.subject}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  controlId="formBasicMessage"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    required={true}
                    name="message"
                    rows={3}
                    onChange={handleChange}
                    value={formData.message}
                    placeholder="Type your message"
                  />
                </Form.Group>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
