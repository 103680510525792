import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { BsX } from "react-icons/bs";
import { motion } from "framer-motion";
import { NavBarContext } from "../assets/contexts/navBarContext";

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: number;
  children: any;
}
const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "transparent",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderRadius: "50px",
  },
};
const CommonModal = ({ children, isOpen, onClose, id }: VideoModalProps) => {
  const [display, setDisply] = useContext(NavBarContext);

  useEffect(() => {
    const isMobile = window.innerWidth <= 767;
    const windowidth = window.innerWidth;
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    if (isOpen) {
      setDisply({ ...display, navHide: "none", overlayHide: true });
      document.body.style.overflow = "hidden";
      document.body.style.paddingTop =
        isMobile || (isLandscape && windowidth < 1000) ? "" : "0";
      // document.body.style.paddingTop = "0";
    }
  }, [isOpen]);

  const closeVideo = () => {
    setDisply({ ...display, navHide: "block", overlayHide: false });
    document.body.style.overflow = "visible";
    const isMobile = window.innerWidth <= 767;
    const windowidth = window.innerWidth;
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    // document.body.style.paddingTop = "140px";
    document.body.style.paddingTop =
      isMobile || (isLandscape && windowidth < 1000) ? "" : "140px";
    onClose();
  };

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onRequestClose={closeVideo}
      contentLabel="Video Modal"
      className={"common-video-modal"}
      overlayClassName={"common-modal-overlay"}
      // style={customStyles}
      // styles={{ overlay: { background: "#FFFF00" } }}
    >
      <div className=" video-modal-container">
        <motion.button
          className="close-button"
          onClick={closeVideo}
          whileHover={{ scale: 1.1, rotate: 360 }}
          whileTap={{ scale: 0.9 }}
          // style={{
          //   position: "absolute",
          //   top: "10px",
          //   right: "10px",
          // }}
        >
          <BsX className="close-icon" />
        </motion.button>
        {children}
      </div>
    </Modal>
  );
};

export default CommonModal;
