import React, { useEffect, useState } from "react";
import { ourCoursesSlider } from "../../../config/sliders";
import CustomSlider from "../../../components/CustomSlider";

import CourseCard from "../../../components/CourseCard";
import { courses } from "../../../assets/mockData/new";

const Courses = () => {
  const [courseData, setCourseData] = useState<any[]>([]);

  useEffect(() => {
    if (courses) {
      const data: any[] = [];
      courses.map((items) => {
        data.push(...items.categoryData);
      });
      setCourseData(data);
    }
  }, []);

  useEffect(() => {
    console.log("courseData", courseData);
  }, [courseData]);

  return (
    <section className="our_courses-section">
      <div className="container">
        <div className="row row-clr heading-row" data-aos="fade-down">
          <h2>Our Courses</h2>
          <p>
            The eClub Business College, a dynamic institution dedicated to
            shaping the next generation
          </p>
          <a href="/courses" className="more-button">
            More
          </a>
        </div>
        {/* <!-- heading-row --> */}
        <div className="row row-clr our_courses_slider" data-aos="fade-up">
          <CustomSlider
            selector=".our_courses_slider"
            settings={ourCoursesSlider}
          >
            {courseData &&
              courseData.map((data) => (
                <div className="slider-inner">
                  <CourseCard {...data} />
                </div>
              ))}

            {/* <div
                className="contnet-wrapper"
                style={{
                  backgroundImage: `url(${Course1})`,
                }}
              >
                <h3>ebay </h3>
                <h4>Selling Guide</h4>
                <div className="row row-clr inner-row">
                  <img
                    src={require("../../../assets/images/course Picture/logo/ebay_logo.png")}
                    className="img-responsive"
                    alt="Image1"
                  />
                  <p>Selling Guide</p>
                  <a
                    className="button-type-3"
                    onClick={() =>
                      navigateToCourseDetails("/course_details/ESG")
                    }
                  >
                    Join Now
                  </a>
                </div>
              </div> */}
            {/* <!-- slider-inner --> */}
          </CustomSlider>
          {/* <!-- slider-inner --> */}
        </div>
        {/* <!-- our_courses-slider --> */}
      </div>
    </section>
  );
};

export default Courses;
