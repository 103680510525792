import React from "react";
import Student from "../../../assets/images/home/4.svg";
import Counter from "../../../components/Counter";
const MidBanner = () => {
  return (
    <section className="mid_banner_section">
      <div className="container">
        <div className="row">
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
            data-aos="fade-left"
          >
            <img src={Student} className="img-responsive" alt="Students" />
            {/* <h3>100k+</h3> */}
            <Counter from={0} to={150} presetString={"K+"} />;
            <h4>Happy Students</h4>
          </div>
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
            data-aos="fade-down"
          >
            <img
              src={require("../../../assets/images/home/5.png")}
              className="img-responsive"
              alt="Courses"
            />
            <Counter from={0} to={13} presetString={"+"} />;<h4>Courses</h4>
          </div>
          <div
            className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
            data-aos="fade-right"
          >
            <img
              src={require("../../../assets/images/home/6.png")}
              className="img-responsive"
              alt="Persons"
            />
            <Counter from={0} to={100} presetString={"+"} />;
            <h4>Resource Persons</h4>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MidBanner;
