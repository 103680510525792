import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { courses } from "../../../assets/mockData/new";
import CourseCard, { CourseCardProps } from "../../../components/CourseCard";

interface courseData {
  categoryId: number;
  categoryName: string;
  categoryData: CourseCardProps[];
}

const CoursesGroup = () => {
  const navigate = useNavigate();
  const [courseState, setCourses] = useState<any>([]);
  const navigateToCourseDetails = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (courses) {
      setCourses(courses);
    }
  }, [courses]);

  useEffect(() => {
    console.log("errr", courseState);
  }, [courseState]);
  return (
    <section className="courses_section">
      <div className="container">
        {courseState &&
          courseState.map((item, categoryId) => {
            return (
              <div key={categoryId} className="row row-clr courses-row">
                <div className="row row-clr heading-row" data-aos="fade-down">
                  <h2>{item.categoryName}</h2>
                </div>
                <div className="row ">
                  {item.categoryData &&
                    item.categoryData.map((cardData, id) => {
                      return (
                        <div
                          key={id}
                          className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                          data-aos="fade-up"
                        >
                          {/* <div
                            className="contnet-wrapper row row-clr"
                            style={{
                              backgroundImage: `url(${cardData.backgroundImage})`,
                              backgroundSize: "300px 350px",
                            }}
                          >
                            <h3>{cardData.courseName} </h3>
                            <div className="row row-clr inner-row">
                              <img
                                src={cardData.logoUrl}
                                className="img-responsive"
                                alt="Image2"
                              />
                              <p>{cardData.subHeading}</p>
                              <a
                                className="button-type-3"
                                onClick={() =>
                                  navigateToCourseDetails(
                                    `/course_details/${cardData.courseCode}`
                                  )
                                }
                              >
                                Join Now
                              </a>
                            </div>
                          </div> */}
                          <CourseCard {...cardData} />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default CoursesGroup;
