export const StudentsLife = [
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/student_life/student_life_1.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/student_life/student_life_2.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/student_life/student_life_3.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/student_life/student_life_4.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/student_life/student_life_5.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/student_life/student_life_6.jpg"
];

export const AcademicExcellence = [
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/academic_excellence/aca_ex_1.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/academic_excellence/aca_ex_2.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/academic_excellence/aca_ex_3.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/academic_excellence/aca_ex_4.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/academic_excellence/aca_ex_5.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/academic_excellence/aca_ex_6.jpg"
];

export const EventsActivitie =[
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_1.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_2.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_3.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_4.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_5.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_7.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_8.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_9.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_10.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/events_activities/event_11.jpg"

]

export const OurStuff =[
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/our_stuff/our_staff_1.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/our_stuff/our_staff_2.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/our_stuff/our_staff_3.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/our_stuff/our_staff_4.jpg",
    "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/life_at_eclub/our_stuff/our_staff_5.jpg"

]
  