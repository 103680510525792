import { useState, CSSProperties, useEffect } from "react";
import HashLoader from "react-spinners/HashLoader";
import { useLoaderContext } from "../assets/contexts/loaderContextProvider";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Loader = () => {
  let [cusLoading, setCusLoading] = useState(false);
  let [color, setColor] = useState("#86b817");
  const { loading, setLoading } = useLoaderContext();

  useEffect(() => {
    setCusLoading(loading);
  }, [loading]);

  return (
    <>
      {cusLoading && (
        <div
          className={`sweet-loading loaderMain ${
            cusLoading ? "visible" : "hidden"
          } `}
        >
          <HashLoader
            color={color}
            loading={cusLoading}
            cssOverride={override}
            size={60}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
};

export default Loader;
