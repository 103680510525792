import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export interface CourseCardProps {
  id: number;
  courseName: string;
  courseCode: string;
  backgroundImage: string;
  subHeading: string;
  logoUrl: string;
  logoAvailable: boolean;
  bodyLabel: string;
  cardImage: string;
}

const CourseCard = (props: CourseCardProps) => {
  const navigate = useNavigate();
  const [isShaking, setIsShaking] = useState(false);

  const handleHoverStart = () => {
    setIsShaking(true);
  };

  const handleHoverEnd = () => {
    setIsShaking(false);
  };

  const navigateToCourseDetails = (path: string) => {
    navigate(path);
  };

  return (
    <motion.div
      className="contnet-wrapper row row-clr"
      key={props.id}
      animate={isShaking ? { x: [0, -10, 10, -10, 10, 0] } : {}}
      transition={{ duration: 0.5 }}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: "300px 350px",
      }}
    >
      <h3>{props.courseName} </h3>
      <div className="row row-clr inner-row">
        <img src={props.logoUrl} className="img-responsive" alt="Image2" />
        <p>{props.subHeading}</p>
        <a
          className="button-type-3"
          onClick={() =>
            navigateToCourseDetails(`/course_details/${props.courseCode}`)
          }
        >
          Join Now
        </a>
      </div>
    </motion.div>
  );
};

export default CourseCard;
