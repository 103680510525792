
const data = [
  { key: 1, css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/class_rooms/057A0210.jpg", height: 600, category: 'Class Rooms' },
  { key: 2, css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/class_rooms/24.jpg", height: 600, category: 'Class Rooms' },
  { key: 3, css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/class_rooms/eClub-01.jpg", height: 600, category: 'Class Rooms' },
  { key: 4, css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/it_room/02.jpg", height: 600, category: 'IT Rooms' },
  { key: 5, css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/it_room/04.jpg", height: 600, category: 'IT Rooms' },
  { key: 6, css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/it_room/1.jpg", height: 600, category: 'IT Rooms' },
  { key: 7, css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/it_room/2.jpg", height: 600, category: 'IT Rooms' },
  { key: 8,css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/trading_room/6W9A8907.jpg",height: 600, category: 'Trading Rooms' },
  { key: 9,css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/trading_room/6W9A8909.jpg",height: 600, category: 'Trading Rooms' },
  { key: 10,css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/trading_room/6W9A8911.jpg",height: 600, category: 'Trading Rooms' },
  { key: 11,css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/trading_room/6W9A8918.jpg",height: 600, category: 'Trading Rooms' },
  { key: 12,css: "https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/facilities/trading_room/6W9A8924.jpg",height: 600, category: 'Trading Rooms' },
];

export default data;
