import React, { useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import WebLogo from "../../assets/images/footer/4470web_logo 1.png";
import Call from "../../assets/images/footer/call.png";
import Mail from "../../assets/images/footer/mail.png";
import Web from "../../assets/images/footer/web.png";
import Messenger from "../../assets/images/footer/Facebook_Messenger_logo_2020 1.png";
import Facebook from "../../assets/images/footer/facebook.png";
import Twitter from "../../assets/images/footer/Twitter.png";
import Instagram from "../../assets/images/footer/instagram.png";
import Linkedin from "../../assets/images/footer/Linkedin.png";
import Logo from "../../assets/images/logo_white.svg";
import { useLoaderContext } from "../../assets/contexts/loaderContextProvider";
import CommonModal from "../CommonModal";
import axiosInstance from "../../api/axiosConfig";
import { useToast } from "../../assets/contexts/toast";
import { FOOTER_INFO } from "../../assets/constants/const";

interface ModalProps {
  isOpen: boolean;
  body: any;
  title: any;
}

const formInitialData = {
  email: "",
};

const modalDefault: ModalProps = { isOpen: false, body: <></>, title: null };

export const Footer = () => {
  const { loading, setLoading } = useLoaderContext();
  const [modalIsOpen, setModalIsOpen] = useState<ModalProps>(modalDefault);
  const { showToast, showSuccessToast, showErrorToast } = useToast();
  const [formData, setFormData] = useState(formInitialData);

  const actionModal = (open: boolean, bodyData, title) => {
    if (open) {
      setModalIsOpen({ isOpen: open, body: bodyData, title: title });
    } else {
      setModalIsOpen({ isOpen: false, body: bodyData, title: title });
    }
  };
  const callCommonModal = (type: any) => {
    setLoading(!loading);
    getEvents(type);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitSubscription = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axiosInstance.post(
        "/api/subscription/save",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response", response);
      if (response && response.status === 200 && response.data) {
        showSuccessToast(
          response.data.success
            ? response.data.success
            : "Submission successful!"
        );

        setFormData(formInitialData);
      }
    } catch (error) {
      showErrorToast(
        error?.response?.data?.error
          ? error.response.data.error
          : "Data Submission Failed"
      );
    } finally {
      setLoading(false);
    }
  };

  const getEvents = async (type: any) => {
    //const decodedValue = await HtmlDecoder(encodedString);
    // actionModal(true, decodedValue);
    try {
      if (type) {
        setLoading(true);
        const response = await axiosInstance.get(`/api/page.php?id=${type}`);
        console.log("response ", response);

        if (response && response.data && response.data.result) {
          let apiResponse = response.data.result;

          const encodedString = response.data.result?.content;
          const title = response.data.result?.title;
          const decodedValue = await HtmlDecoder(encodedString);
          actionModal(true, decodedValue, title);
          // setMarkedDates(transformedData);
        }
      } else {
        throw new Error("Please provide type");
      }
    } catch (error) {
      if (error) {
        showErrorToast(error);
      } else {
        showErrorToast("Failed to fetch footer data. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 left">
              <img
                src={Logo}
                style={{ width: "115px", height: "42px" }}
                className="img-responsive footer-logo"
                alt="WebLogo"
              />
              <p>No 651, Nalluruwa, Galle Road, Panadura, Sri Lanka</p>
              <ul>
                <li>
                  <a href="tel:+94755621621">
                    <img src={Call} className="img-responsive" alt="Call" />
                    0755 621 621
                  </a>
                </li>
                <li>
                  <a href="mailto:info@eclub.lk">
                    <img src={Mail} className="img-responsive" alt="Mail" />
                    info@eclub.lk
                  </a>
                </li>
                <li>
                  <a
                    href="www.eclub.lk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Web} className="img-responsive" alt="Web" />
                    www.eclub.lk
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 right">
              <div className="row row-clr">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/eclub.lk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={Facebook}
                        className="img-responsive"
                        alt="Facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/eclub_international/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={Instagram}
                        className="img-responsive"
                        alt="Instagram"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/eClub_LK"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={Twitter}
                        className="img-responsive"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/eclub-business-college/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={Linkedin}
                        className="img-responsive"
                        alt="Linkedin"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="row row-clr subscribe-wrapper float-end">
                <Form id="subscription" onSubmit={submitSubscription}>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      name="email"
                      required={true}
                      placeholder="Your Email Address"
                      onChange={handleChange}
                      value={formData.email}
                      className="subscription"
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary pull-right"
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Subscribe"
                      )}
                    </Button>
                  </Form.Group>
                </Form>
              </div>
              {/* <!-- subscribe-wrapper --> */}
            </div>
          </div>
          <div className="row bottom-row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <p>
                ©<span>{new Date().getFullYear()} </span>
                eClub. All rights reserved
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ul className="float-end">
                <li>
                  <a onClick={() => callCommonModal(FOOTER_INFO.FAQ)}>FAQ</a>
                </li>
                <li>
                  <a onClick={() => callCommonModal(FOOTER_INFO.PAP)}>
                    Privacy & Policy
                  </a>
                </li>
                <li>
                  <a onClick={() => callCommonModal(FOOTER_INFO.TNC)}>
                    Terms & Condition
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- bottom-row --> */}
        </div>
      </footer>
      {modalIsOpen.isOpen && (
        <CommonModal
          isOpen={modalIsOpen.isOpen}
          onClose={() => actionModal(false, <></>, "")}
        >
          <h2 id="parent-modal-title">{modalIsOpen.title}</h2>
          {/* <img src="https://eclubdrive.s3.us-east-2.amazonaws.com/web/photos/news_events/daily-news-head-2-2.jpg" /> */}
          <div
            style={{ overflow: "auto", height: "80vh" }}
            dangerouslySetInnerHTML={{
              __html: modalIsOpen.body,
            }}
          />
        </CommonModal>
      )}
    </>
  );
};

const HtmlDecoder = async (encodedData: string) => {
  const encodedString = encodedData;

  const parser = new DOMParser();
  const decodedDocument = parser.parseFromString(encodedString, "text/html");

  const decodedString = decodedDocument.body.textContent;
  return decodedString;
};
