// src/components/Calendar.js
import React, { useEffect, useLayoutEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Tooltip } from "react-tooltip";
import { useToast } from "../assets/contexts/toast";
import axiosInstance from "../api/axiosConfig";
// import './Calendar.css'; // Import your custom styles

interface EventCalendar {
  content: (data: any) => void;
}

const MyCalendar = ({ content }: EventCalendar) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { showToast, showSuccessToast, showErrorToast } = useToast();
  const [markedDates, setMarkedDates] = useState([]);
  const [isCustomTileClicked, setIsCustomTileClicked] = useState(new Date());

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        "https://www.eclub.lk/api/calendar/events"
      );
      if (response && response.data && response.data.result) {
        let apiResponse = response.data.result;
        const transformedData = apiResponse.map((item) => ({
          ...item,
          date: new Date(item.date),
        }));

        setMarkedDates(transformedData);
      }
    } catch (error) {
      showErrorToast("Failed to fetch events. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    console.log("TRI date", date);
    setSelectedDate(date);
    setIsCustomTileClicked(date);
  };

  useEffect(() => {
    console.log("markedDates", markedDates);
    const fetchData = async () => {
      if (selectedDate && markedDates) {
        const markedDate = markedDates.find(
          (d) => d.date.toDateString() === new Date(selectedDate).toDateString()
        );

        if (markedDate?.children) {
          //content is a callback
          content(markedDate.children);
        }
      }
    };

    fetchData();
  }, [selectedDate, markedDates]);

  const tileContent = ({ date, view }) => {
    const markedDate = markedDates?.find(
      (d) => d.date.toDateString() === new Date(date).toDateString()
    );
    const customTileClass =
      isCustomTileClicked &&
      isCustomTileClicked.toDateString() === date.toDateString()
        ? "calendar-clicked"
        : "custom-tile";

    return (
      <div
        // className="custom-tile"
        style={{
          backgroundColor: markedDate?.children[0]?.color || "",
        }}
        className={`custom-tile ${customTileClass}`}
        // onClick={handleDateChange}
      >
        {view === "month" ? date.getDate() : null}
      </div>
    );
  };

  return (
    <div className="custom-calendar-container">
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        className="custom-calendar"
        tileContent={tileContent}
      />
    </div>
  );
};

export default MyCalendar;
